
import { defineComponent } from "vue";
import OverlayPanel from "primevue/overlaypanel";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import Calendar from "primevue/calendar";

export default defineComponent({
  components: {
    OverlayPanel,
    Button,
    RadioButton,
    Calendar,
  },
  emits: ["save"],
  props: {
    type: {
      type: String,
      required: false,
    },
    date: {
      type: Date,
      required: false,
    },
  },
  data: () => ({
    eventType: "now",
    eventAt: new Date(),
  }),
  methods: {
    toggle(event: Event) {
      (this.$refs.overlayPanel as OverlayPanel).toggle(event);
    },
    saveSelection() {
      if (this.eventType === "now") {
        this.eventAt = new Date();
      }

      this.$emit("save", {
        eventType: this.eventType,
        eventAt: this.eventAt,
      });
    },
  },
  watch: {
    type: {
      handler(value) {
        this.eventType = value ?? "now";
      },

      immediate: true,
    },
    date: {
      handler(value) {
        this.eventAt = value ?? new Date();
      },
      immediate: true,
    },
  },
});
