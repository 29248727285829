import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-178b1901"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-semibold text-lg pl-4" }
const _hoisted_2 = { class: "flex gap-2 overflow-auto pl-4 no-scrollbar py-1" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex justify-content-center align-items-center bg-white p-2 border-round-lg border-1 border-300 text-900" }
const _hoisted_5 = { class: "text-sm font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("route_planner.mobility")), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modes, (mode) => {
        return (_openBlock(), _createElementBlock("button", {
          key: mode.value,
          class: _normalizeClass(["flex flex-column justify-content-center align-items-center gap-2 h-5rem border-round-2xl border-none flex-shrink-0 cursor-pointer px-3", {
        'bg-primary': _ctx.selectedMode === mode.value,
        'bg-primary-50': _ctx.selectedMode !== mode.value,
      }]),
          style: {"min-width":"5rem"},
          onClick: ($event: any) => (_ctx.selectedMode = mode.value)
        }, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(mode.icon), { class: "text-lg" }))
          ]),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(`route_planner.modes.${mode.value}`)), 1)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ], 64))
}