import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/relatedAccounts.svg'


const _hoisted_1 = {
  key: 0,
  class: "userDashboard"
}
const _hoisted_2 = ["alt"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "verificationStatus" }
const _hoisted_5 = { class: "dialogContent" }
const _hoisted_6 = { class: "dialogContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RelatedAccountsOne = _resolveComponent("RelatedAccountsOne")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_SidebarUserWrapper = _resolveComponent("SidebarUserWrapper")!

  return (_openBlock(), _createBlock(_component_SidebarUserWrapper, {
    title: _ctx.$t('user.related_accounts'),
    sidebarComponent: _ctx.sidebarComponent
  }, {
    default: _withCtx(() => [
      (_ctx.loaded)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.relatedAccounts.total === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: _ctx.$t('accessibility.related_accounts.related_accounts'),
                    class: "relatedAccountsImg"
                  }, null, 8, _hoisted_2),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("related_accounts.connect_user_accounts")), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("related_accounts.description")), 1),
                  _createVNode(_component_Button, {
                    class: "defaultButton",
                    label: _ctx.$t('related_accounts.connect_accounts'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.$router.push({
              name: 'RelatedAccountAdd',
            })
          ))
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.relatedAccounts.total !== 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("related_accounts.already_connected_accounts")), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedAccounts.data, (relatedAccount, index) => {
                    return (_openBlock(), _createBlock(_component_RelatedAccountsOne, {
                      key: index,
                      relatedAccount: relatedAccount,
                      onUpdate: ($event: any) => (_ctx.updateRelatedAccount(relatedAccount, $event)),
                      onDelete: ($event: any) => (_ctx.showConfirmDeletionDialog(relatedAccount))
                    }, null, 8, ["relatedAccount", "onUpdate", "onDelete"]))
                  }), 128)),
                  _createVNode(_component_Button, {
                    "event-name": "connectPaymentProviderButton",
                    "event-from": "/user/related-accounts",
                    class: "defaultButton",
                    label: _ctx.$t('related_accounts.connect_more_accounts'),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'RelatedAccountAdd' })))
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_Dialog, {
        visible: _ctx.showDeleteAccountDialog,
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDeleteAccountDialog) = $event)),
        class: "confirmationDialog"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("related_accounts.confirm_deletion")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Button, {
              class: "defaultButton",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteRelatedAccount(_ctx.relatedAccountToDelete))),
              style: {"justify-content":"center"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("related_accounts.delete_account")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Button, {
              class: "defaultButton",
              onClick: _ctx.cancelDeletion,
              style: {"justify-content":"center"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("global.cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _: 1
  }, 8, ["title", "sidebarComponent"]))
}