<template>
  <div
    class="flex flex-column bg-white border-round-3xl border-1 border-300 p-4 w-20rem sm:w-25rem h-28rem cursor-pointer"
    tabindex="1"
    @click="$router.push({ name: 'RoutePlanner' })"
  >
    <span class="font-semibold text-xl">{{
      $t("widgets.route_planner.title")
    }}</span>
    <p class="text-base text-600">
      {{ $t("widgets.route_planner.description") }}
    </p>
    <img src="/images/routePlanner/plan-route.svg" alt="" class="mt-auto" />
  </div>
</template>
