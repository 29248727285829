
import { defineComponent } from "vue";
import Button from "primevue/button";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  components: { Button },
  mounted() {
    firebaseLogOutComeEvent("/register/success", "registration", {
      user_type: "registered",
      registration_type: "normal",
    });
  },
});
