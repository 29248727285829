<template>
  <div>
    <p>
      <strong>{{ $t("payments.enter_data_card") }}</strong>
    </p>
    <CardInput :showSaveSwitch="true" @continue="nextStep"></CardInput>
    <a href="#" @click="$emit('previous')" class="cancel">
      {{ $t("payments.previous_step") }}
    </a>
    <div class="paymentValue">
      <span
        >{{ $t("payments.value_of_purchase") }}:
        <span class="black">
          {{
            (invoice.amount / 10000).toLocaleString($i18n.locale, {
              maximumFractionDigits: 2,
            })
          }}
          {{ invoice.currency }}
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import CardInput from "@/components/user/myPayments/CardInput";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    firebaseLogOutComeEvent(
      "/user/my-payments/invoices/*/payment",
      "paymentProcess",
      {
        payment_value: "null",
        service_type: "null",
        process_step: 2,
      },
    );
  },
  methods: {
    nextStep({ token, cardData, saveCard, customName }) {
      if (window.FlutterChannel) {
        window.FlutterChannel.postMessage(
          JSON.stringify({
            cardData,
            token,
            saveCard,
            customName,
          }),
        );
        return;
      }
      this.$emit("submit", {
        token,
        saveCard,
        customName,
      });
    },
  },
  components: { CardInput },
});
</script>
<style scoped></style>
