import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "fillDialogTitle pb-0" }
const _hoisted_2 = { class: "fillDialogButtons" }
const _hoisted_3 = { class: "fillDialogTitle" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { class: "fillDialogButtons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderForm = _resolveComponent("OrderForm")!
  const _component_Overview = _resolveComponent("Overview")!
  const _component_Confirmation = _resolveComponent("Confirmation")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step === 1)
      ? (_openBlock(), _createBlock(_component_OrderForm, {
          key: 0,
          order: _ctx.form,
          onContinue: _ctx.toStepTwo
        }, null, 8, ["order", "onContinue"]))
      : _createCommentVNode("", true),
    (_ctx.step === 2)
      ? (_openBlock(), _createBlock(_component_Overview, {
          key: 1,
          order: _ctx.form,
          onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.step = 1)),
          onOrder: _ctx.submitForm
        }, null, 8, ["order", "onOrder"]))
      : _createCommentVNode("", true),
    (_ctx.step === 3)
      ? (_openBlock(), _createBlock(_component_Confirmation, {
          key: 2,
          order: _ctx.form,
          successful: _ctx.successful,
          onBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.step = 1))
        }, null, 8, ["order", "successful"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.leaveConfirmDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.leaveConfirmDialog) = $event)),
      class: "successDialog",
      tabindex: "1",
      closable: false,
      draggable: false,
      modal: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("waste_take_away.leave_title")), 1),
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("waste_take_away.leave_description")), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            "event-name": "wasteFormLeaveEditingButtonClick",
            "event-from": "/user/my-private-waste/takeaway",
            class: "defaultButton",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.leaveRoute(true))),
            tabindex: "1"
          }, _toDisplayString(_ctx.$t("waste_take_away.leave_confirm")), 1),
          _createElementVNode("span", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.leaveRoute(false))),
            onKeypress: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.leaveRoute(false)), ["enter"])),
            tabindex: "1"
          }, _toDisplayString(_ctx.$t("waste_take_away.leave_cancel")), 33)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showFillDialog,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showFillDialog) = $event)),
      class: "successDialog",
      tabindex: "1",
      closable: false,
      draggable: false,
      modal: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("forms.application_forum.dialog.title")), 1),
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("forms.application_forum.dialog.description")) + ":", 1),
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t("forms.application_forum.name_and_surname")) + ":", 1),
            _createTextVNode(" " + _toDisplayString(_ctx.user.first_name) + " " + _toDisplayString(_ctx.user.last_name), 1)
          ]),
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t("forms.application_forum.email")) + ":", 1),
            _createTextVNode(" " + _toDisplayString(_ctx.user.email), 1)
          ]),
          (_ctx.user.phone_number)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("forms.application_forum.phone_number")) + ":", 1),
                _createTextVNode(" " + _toDisplayString(_ctx.user.phone_number), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.contact_info && _ctx.user.contact_info.address)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("forms.application_forum.address")) + ":", 1),
                _createTextVNode(" " + _toDisplayString(_ctx.user.contact_info.address), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.contact_info && _ctx.user.contact_info.house_number)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("forms.application_forum.house_number")) + ":", 1),
                _createTextVNode(" " + _toDisplayString(_ctx.user.contact_info.house_number), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.contact_info && _ctx.user.contact_info.code)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("forms.application_forum.code")) + ":", 1),
                _createTextVNode(" " + _toDisplayString(_ctx.user.contact_info.code), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.contact_info && _ctx.user.contact_info.city)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("forms.application_forum.city")) + ":", 1),
                _createTextVNode(" " + _toDisplayString(_ctx.user.contact_info.city), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            "event-name": "prefillUsersDataButton",
            "event-from": "/user/my-private-waste/takeaway",
            class: "defaultButton",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.fillForm && _ctx.fillForm(...args))),
            tabindex: "1"
          }, _toDisplayString(_ctx.$t("forms.application_forum.dialog.fill_data")), 1),
          _createElementVNode("span", {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showFillDialog = false)),
            tabindex: "1",
            onKeypress: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.showFillDialog = false), ["enter"]))
          }, _toDisplayString(_ctx.$t("forms.application_forum.dialog.manual")), 33)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}