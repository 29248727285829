
import { defineComponent } from "vue";
import Password from "primevue/password";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    verificationToken: null,
    error: false,
  }),
  methods: {
    checkVerificationToken() {
      this.$http
        .post("api/v1/register-verification", {
          type: this.type,
          verification_token: this.verificationToken,
          emailOrPhone: this.email,
        })
        .then(() => {
          firebaseLogOutComeEvent(
            "/register",
            "registrationProcessSecondStep",
            {
              user_email_or_phone_number: this.email,
              user_type: "registered",
              registration_type: "normal",
              process_step: 2,
              user_id: "null",
            },
          );
          firebaseLogOutComeEvent("/register", "profileVerification");
          this.$emit("submit");
        })
        .catch(() => {
          this.error = true;
        });
    },
    resendCode() {
      this.$http.post("api/v1/register-verification-request", {
        type: this.type,
        emailOrPhone: this.email,
      });
    },
  },
  components: { Password },
  props: {
    type: {
      type: String,
      required: true,
    },
    email: { type: String, required: true },
  },
});
