
import { PropType, defineComponent } from "vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import IconHome from "~icons/tabler/home";
import IconBuildingSkyscraper from "~icons/tabler/building-skyscraper";
import IconSchool from "~icons/tabler/school";
import IconBusStop from "~icons/tabler/bus-stop";
import IconBuildingFactory from "~icons/tabler/building-factory";
import IconBuilding from "~icons/tabler/building";
import IconBuildingHospital from "~icons/tabler/building-hospital";
import IconPlane from "~icons/tabler/plane";
import IconBuildingChurch from "~icons/tabler/building-church";
import IconBuildingCastle from "~icons/tabler/building-castle";
import IconBuildingStore from "~icons/tabler/building-store";
import IconPencil from "~icons/tabler/pencil";
import IconMapPin from "~icons/tabler/map-pin";
import {
  FavouriteLocation,
  FavouriteLocationFormErrors,
} from "@/typings/RoutePlanner";

export default defineComponent({
  components: {
    Dialog,
    InputText,
    Button,
    IconMapPin,
    IconHome,
    IconBuildingSkyscraper,
    IconSchool,
    IconBusStop,
    IconBuildingFactory,
    IconBuilding,
    IconBuildingHospital,
    IconPlane,
    IconBuildingChurch,
    IconBuildingCastle,
    IconBuildingStore,
    IconPencil,
  },

  emits: ["close", "refresh"],

  props: {
    location: {
      type: Object as PropType<FavouriteLocation | null>,
      default: null,
    },
  },

  data: () => ({
    icons: [
      {
        value: "map-pin",
        icon: IconMapPin,
      },
      {
        value: "home",
        icon: IconHome,
      },
      {
        value: "building-skyscraper",
        icon: IconBuildingSkyscraper,
      },
      {
        value: "school",
        icon: IconSchool,
      },
      {
        value: "bus-stop",
        icon: IconBusStop,
      },
      {
        value: "building-factory",
        icon: IconBuildingFactory,
      },
      {
        value: "building",
        icon: IconBuilding,
      },
      {
        value: "building-hospital",
        icon: IconBuildingHospital,
      },
      {
        value: "plane",
        icon: IconPlane,
      },
      {
        value: "building-church",
        icon: IconBuildingChurch,
      },
      {
        value: "building-castle",
        icon: IconBuildingCastle,
      },
      {
        value: "building-store",
        icon: IconBuildingStore,
      },
    ],
    selectedIcon: "map-pin",
    form: {
      title: "",
      address: "",
      latitude: "",
      longitude: "",
    },
    errors: {} as FavouriteLocationFormErrors,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    setAddress(place: GmapPlaceResult) {
      const address = place.formatted_address;
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.form.address = address;
      this.form.latitude = latitude.toString();
      this.form.longitude = longitude.toString();
    },
    validateForm() {
      this.errors = {};

      if (!this.form.title) {
        this.errors.title = this.$t("error.title");
      }
      if (!this.form.address || !this.form.latitude || !this.form.longitude) {
        this.errors.address = this.$t("error.address");
      }
    },
    async saveLocation() {
      if (!this.user) {
        return;
      }

      this.validateForm();

      if (Object.keys(this.errors).length !== 0) {
        return;
      }

      try {
        const locationData: FavouriteLocation = {
          title: this.form.title,
          address: this.form.address,
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          icon: this.selectedIcon,
        };

        if (this.location?.id) {
          await this.$maas.put(
            `api/v1/citizen-locations/${this.location.id}`,
            locationData,
          );
        } else {
          await this.$maas.post("api/v1/citizen-locations", locationData);
        }

        this.$emit("refresh");

        this.clearForm();

        this.$emit("close");
      } catch (error) {
        this.errors.location_error = this.$t("error.location_error");
        return;
      }
    },
    clearForm() {
      this.form = {
        title: "",
        address: "",
        latitude: "",
        longitude: "",
      };
      this.selectedIcon = "map-pin";
      this.errors = {};

      this.$emit("close");
    },
  },

  watch: {
    location: {
      immediate: true,
      handler(newLocation: FavouriteLocation | null) {
        if (!newLocation) {
          this.clearForm();
          return;
        }

        this.form.title = newLocation.title;
        this.form.address = newLocation.address;
        this.form.latitude = newLocation.latitude;
        this.form.longitude = newLocation.longitude;
        this.selectedIcon = newLocation.icon || "map-pin";
      },
    },
  },
});
