import { ActionContext } from "vuex";
import { FavouriteLocation, Route } from "@/typings/RoutePlanner";

interface State {
  route: Route | null;
  selectedLocation: FavouriteLocation | null;
}

const state = (): State => ({
  route: null,
  selectedLocation: null,
});

const getters = {
  route: (state: State): Route | null => {
    return state.route;
  },
  selectedLocation: (state: State): FavouriteLocation | null => {
    return state.selectedLocation;
  },
};

const mutations = {
  setRoute(state: State, route: Route): void {
    state.route = route;
  },
  setSelectedLocation(state: State, location: FavouriteLocation): void {
    state.selectedLocation = location;
  },
};

const actions = {
  setRoute(context: ActionContext<State, State>, route: Route): void {
    context.commit("setRoute", route);
  },
  setSelectedLocation(
    context: ActionContext<State, State>,
    location: FavouriteLocation,
  ): void {
    context.commit("setSelectedLocation", location);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
