<template>
  <div>
    <h2>{{ $t("user_email.verify_email") }}</h2>
    <hr class="primaryShort" />
    <p>{{ $t("user_email.enter_code_from_email") }}</p>
    <form class="form" @submit.prevent="checkCode">
      <ul>
        <li>
          <span class="p-float-label">
            <InputText
              id="verificationCode"
              type="text"
              v-model="verificationCode"
              name="verificationCode"
            />
            <label for="verificationCode">{{
              $t("user_email.enter_code")
            }}</label>
          </span>
          <p style="color: red" v-if="error">
            {{ $t("user_email.wrong_code") }}
          </p>
        </li>
      </ul>
      <hr class="grayWide" />
      <Button
        class="defaultButton whiteBG margin-0"
        :label="$t('user_email.check')"
        @click.prevent="checkCode()"
      />
    </form>
    <router-link :to="{ name: 'MyProfile' }" class="cancel"
      >{{ $t("global.cancel") }}
    </router-link>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    verificationCode: "",
    error: false,
  }),
  methods: {
    checkCode() {
      this.$http
        .post("api/v1/verification", {
          type: "email",
          verification_token: this.verificationCode,
        })
        .then(async () => {
          firebaseLogOutComeEvent(
            "/user/my-profile/email-verification",
            "profileVerification",
          );
          await this.$store.dispatch("user/userinfo");
          this.$router.push({ name: "VerificationSuccess" });
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
  components: { InputText, Button },
});
</script>

<style scoped></style>
