
import { defineComponent } from "vue";
import Checkbox from "primevue/checkbox";
import Menu from "primevue/menu";
import { Filter } from "@/typings/News";
import { QueryParameters } from "@/typings/QueryParameters";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    categories: null as null | Array<Filter>,
    subscribe: {
      categories_phone: false,
      categories_mail: false,
      categories_push: false,
    },
    selectedCategory: null as null | Filter,
    searchQuery: "",
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    searchedCategories() {
      if (this.searchQuery.trim() === "") {
        return this.categories;
      }

      return this.categories?.filter((category) =>
        category.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
  },
  methods: {
    loadCategories() {
      let params: QueryParameters = {
        perPage: 30,
      };

      if (this.searchQuery !== "") {
        params.filters = {
          ...params.filters,
          name: encodeURIComponent("%" + this.searchQuery.trim() + "%"),
        };
      }

      this.$http
        .get("api/v2/events/categories", { params })
        .then((response) => {
          this.categories = response.data.data
            .filter((cat: Filter) => this.$te(`events.categories.${cat.name}`))
            .map((cat: Filter) => ({
              ...cat,
              name: this.$t(`events.categories.${cat.name}`),
            }));
        });
    },
    loadSubscriptions() {
      this.$http.get("api/v1/events/subscriptions").then((response) => {
        if (response && response.data.total > 0) {
          this.subscribe = response.data.data[0];
        }
      });
    },
    saveSubscriptions() {
      this.$http
        .post("api/v1/events/subscriptions", this.subscribe)
        .then(() => {
          this.loadSubscriptions();
          firebaseLogOutComeEvent(
            "/events/subscriptions",
            "notificationRegistratioEvents",
            {
              sms_enabled: this.subscribe.categories_phone,
              mail_enabled: this.subscribe.categories_mail,
              push_enabled: this.subscribe.categories_push,
            },
          );
        });
    },
    updateSubscription(category: Filter, subscribed: boolean) {
      if (subscribed) {
        firebaseLogOutComeEvent(
          "/events/subscriptions",
          "eventsNotificationRegistrationProcess",
          {
            subscribe_to: category.name,
            sms_enabled: this.subscribe.categories_phone,
            mail_enabled: this.subscribe.categories_mail,
            push_enabled: this.subscribe.categories_push,
          },
        );
        firebaseLogOutComeEvent(
          "/events/subscriptions",
          "notificationRegistrationProcessEvents",
          {
            process_step: 1,
            subscribe_to: category.name,
            sms_enabled: this.subscribe.categories_phone,
            mail_enabled: this.subscribe.categories_mail,
            push_enabled: this.subscribe.categories_push,
          },
        );
      }

      this.$http
        .post("/api/v1/events/category-subscribers", {
          category_id: category.id,
          subscribed,
        })
        .then(() => this.loadCategories());
    },
    toggle(event: MouseEvent | KeyboardEvent) {
      (this.$refs.menu as Menu).toggle(event);
    },
    firebaseOutComeLog() {
      firebaseLogOutComeEvent(
        "/events/subscriptions",
        "eventsNotificationRegistrationProcess",
        {
          sms_enabled: this.subscribe.categories_phone,
          mail_enabled: this.subscribe.categories_mail,
          push_enabled: this.subscribe.categories_push,
        },
      );
      firebaseLogOutComeEvent(
        "/events/subscriptions",
        "notificationRegistrationProcessEvents",
        {
          sms_enabled: this.subscribe.categories_phone,
          mail_enabled: this.subscribe.categories_mail,
          push_enabled: this.subscribe.categories_push,
          process_step: 2,
        },
      );
    },
  },
  mounted() {
    this.loadCategories();
    this.loadSubscriptions();
  },
  components: {
    Checkbox,
    Menu,
  },
});
