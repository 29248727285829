
import { firebaseLogOutComeEvent } from "@/plugins/firebase";
import { Invoice } from "@/typings/Invoice";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    invoice: {
      type: Object as () => Invoice | null,
    },
  },
  mounted() {
    firebaseLogOutComeEvent(
      "/user/my-payments/invoices/*/payment",
      "paymentProcess",
      {
        payment_value: "null",
        service_type: "null",
        process_step: 3,
      },
    );
  },
  methods: {
    payment() {
      this.$emit("submit");
    },
  },
});
