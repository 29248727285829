import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-column" }
const _hoisted_2 = { class: "flex flex-column align-items-center gap-3 w-full sm:flex-row bg-white p-3 border-bottom-1 border-300 sm:px-5" }
const _hoisted_3 = { class: "wp1200 flex w-full p-0" }
const _hoisted_4 = { class: "flex gap-2 justify-content-center w-full" }
const _hoisted_5 = { class: "flex gap-1 p-2 border-1 border-300 border-round-xl w-fit h-fit text-base" }
const _hoisted_6 = { class: "flex flex-column align-items-center gap-3 w-full sm:flex-row bg-white p-2 border-bottom-1 border-300 sm:px-5" }
const _hoisted_7 = { class: "wp1200 flex w-full justify-content-center align-items-center text-base" }
const _hoisted_8 = { class: "flex flex-column align-items-center sm:flex-row gap-2" }
const _hoisted_9 = { class: "text-600" }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { class: "flex flex-column align-items-center sm:flex-row gap-2" }
const _hoisted_12 = { class: "text-600" }
const _hoisted_13 = { class: "font-semibold" }
const _hoisted_14 = { class: "flex flex-column align-items-center sm:flex-row gap-2" }
const _hoisted_15 = { class: "text-600" }
const _hoisted_16 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_IconWalk = _resolveComponent("IconWalk")!
  const _component_IconBike = _resolveComponent("IconBike")!
  const _component_IconCar = _resolveComponent("IconCar")!
  const _component_IconChevronRight = _resolveComponent("IconChevronRight")!
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, {
          class: "bg-transparent border-none text-900 items-center justify-center",
          onClick: _ctx.goBack
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent('IconChevronLeft')))
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.route.legs, (leg, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "flex gap-1 align-items-center"
            }, [
              _createElementVNode("div", _hoisted_5, [
                (leg.mode === 'WALK')
                  ? (_openBlock(), _createBlock(_component_IconWalk, { key: 0 }))
                  : _createCommentVNode("", true),
                (leg.mode === 'BICYCLE' || leg.mode === 'BICYCLE_RENT')
                  ? (_openBlock(), _createBlock(_component_IconBike, { key: 1 }))
                  : _createCommentVNode("", true),
                (leg.mode === 'CAR')
                  ? (_openBlock(), _createBlock(_component_IconCar, { key: 2 }))
                  : _createCommentVNode("", true)
              ]),
              (index !== _ctx.route.legs.length - 1)
                ? (_openBlock(), _createBlock(_component_IconChevronRight, {
                    key: 0,
                    class: "text-sm text-600"
                  }))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("route_planner.duration")), 1),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.getDuration(_ctx.route)), 1)
        ]),
        _createVNode(_component_Divider, { layout: "vertical" }),
        _createElementVNode("span", _hoisted_11, [
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("route_planner.distance")), 1),
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.getDistance(_ctx.route)), 1)
        ]),
        _createVNode(_component_Divider, { layout: "vertical" }),
        _createElementVNode("span", _hoisted_14, [
          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("route_planner.arrival_time")), 1),
          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.getArrivalTime(_ctx.route)), 1)
        ])
      ])
    ])
  ]))
}