
import { defineComponent, PropType } from "vue";
import { Journey, Route } from "@/typings/RoutePlanner";
import IconWalk from "~icons/tabler/walk";
import IconBike from "~icons/tabler/bike";
import IconLeaf from "~icons/tabler/leaf";

export default defineComponent({
  components: { IconWalk, IconBike, IconLeaf },
  props: {
    journey: {
      type: Object as PropType<Journey>,
      required: true,
    },
  },
  computed: {
    greenestRoutes(): Route[] {
      const walkRoutes = this.journey.routes.filter(
        (route) => route.legs[0].mode === "WALK",
      );
      const bikeRoutes = this.journey.routes.filter(
        (route) => route.legs[0].mode === "BICYCLE",
      );

      return [
        walkRoutes.sort(
          (a, b) => a.co2Emissions.value - b.co2Emissions.value,
        )[0],
        bikeRoutes.sort(
          (a, b) => a.co2Emissions.value - b.co2Emissions.value,
        )[0],
      ].filter((route) => route !== undefined);
    },
  },
  methods: {
    handleRouteSelection(route: Route) {
      this.$store.dispatch("route-planner/setRoute", route);
      this.$router.push({ name: "SingleRoutePlannerView" });
    },
    formatTime(timeString: string) {
      return new Date(timeString).toLocaleTimeString("sl", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },
    formatDuration(route: Route) {
      const start = new Date(route.startTime);
      const end = new Date(route.endTime);
      const durationInMinutes = Math.round(
        (end.getTime() - start.getTime()) / 60000,
      );

      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;

      return hours >= 1 ? `${hours} h ${minutes} min` : `${minutes} min`;
    },
    convertJoulesToKcal(joules: number) {
      return joules * 0.000239006;
    },
  },
});
