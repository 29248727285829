
import { defineComponent } from "vue";
import { Leg, Route } from "@/typings/RoutePlanner";
import RouteDetailsHeader from "@/components/routePlanner/RouteDetailsHeader.vue";
import Map from "@/components/Map.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { GeoJson } from "@/typings/GeoJson";
import Button from "primevue/button";
import RouteSteps from "@/components/routePlanner/RouteSteps.vue";
import IconWalk from "~icons/tabler/walk";
import IconBike from "~icons/tabler/bike";
import IconCar from "~icons/tabler/car";
import IconMapPin from "~icons/tabler/map-pin";
import IconFlag from "~icons/tabler/flag";
import IconWind from "~icons/tabler/wind";
import IconBarbell from "~icons/tabler/barbell";
import IconLock from "~icons/tabler/lock";
import { useCssVar } from "@vueuse/core";
import GreenRouteCards from "@/components/routePlanner/GreenRouteCards.vue";
import LegBikeInfo from "@/components/routePlanner/LegBikeInfo.vue";

export default defineComponent({
  components: {
    RouteDetailsHeader,
    Map,
    Button,
    IconWalk,
    IconBike,
    IconCar,
    IconFlag,
    IconMapPin,
    IconWind,
    IconBarbell,
    IconLock,
    RouteSteps,
    GreenRouteCards,
    LegBikeInfo,
  },

  setup() {
    const primaryColor = useCssVar("--primary-color");

    return { primaryColor };
  },

  data: () => ({
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    zoom: 15,
    markers: [] as Array<Marker>,
    geoJson: [] as GeoJson[],
  }),

  computed: {
    route() {
      const route = this.$store.getters["route-planner/route"] as Route;

      if (route) {
        route.legs = route.legs.map((leg) => ({
          ...leg,
          showSteps: leg.showSteps ?? false,
        }));
      }

      return route;
    },
    icons() {
      return [
        {
          value: "walk",
          icon: IconWalk,
        },
        {
          value: "bicycle",
          icon: IconBike,
        },
        {
          value: "bicycle_rent",
          icon: IconBike,
        },
        {
          value: "car",
          icon: IconCar,
        },
      ];
    },
  },

  methods: {
    getIconComponent(iconValue: string) {
      const icon = this.icons.find(
        (item) => item.value.toLowerCase() === iconValue.toLowerCase(),
      );
      return icon ? icon.icon : IconWalk;
    },
    getDuration(leg: Leg) {
      const duration = this.$moment.duration(
        this.$moment(leg.endTime).diff(this.$moment(leg.startTime)),
      );

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());

      return hours >= 1 ? `${hours} h ${minutes} min` : `${minutes} min`;
    },
    getDistance(distance: number) {
      const meters = Math.floor(distance);
      const kilometers = Math.floor(meters / 1000);
      const remainingMeters = Math.floor(meters % 1000);

      return kilometers > 0
        ? `${kilometers} km ${remainingMeters} m`
        : `${meters} m`;
    },
    convertJoulesToKcal(joules: number) {
      return joules * 0.000239006;
    },
    showBikeInfo(leg: Leg, index: number) {
      if (index === this.route.legs.length - 1) {
        return false;
      }

      if (!leg.start.bikeStation && leg.end.bikeStation) {
        return false;
      }

      return true;
    },
    getToName(leg: Leg, index: number) {
      if (index === this.route.legs.length - 1) {
        return leg.steps[leg.steps.length - 1].streetName;
      }

      return leg.end.name;
    },
    displayMarkers() {
      const firstStep = this.route.legs[0].steps[0];
      const lastStep =
        this.route.legs[this.route.legs.length - 1].steps[
          this.route.legs[this.route.legs.length - 1].steps.length - 1
        ];

      this.markers = [
        {
          position: { lat: firstStep.lat, lng: firstStep.lon },
          icon: "/markers/start-pin.svg",
        },
        {
          position: { lat: lastStep.lat, lng: lastStep.lon },
          icon: "/markers/end-pin.svg",
        },
      ] as Array<Marker>;
    },
    displayPolygons() {
      if (!this.route) {
        return;
      }

      this.geoJson = [];
      this.route.legs.forEach((leg, index) => {
        this.geoJson.push({
          id: `leg-${index}`,
          geometry: {
            type: "LineString",
            geometryString: leg.legGeometry.points,
          },
          path: leg.steps.map((step) => ({ lat: step.lat, lng: step.lon })),
          properties: {
            geometry: leg.legGeometry.points,
            strokeWidth: 5,
            isDashed:
              this.route.legs.length > 1 && !leg.showSteps ? true : false,
            showCircles: true,
          },
          color: this.primaryColor,
        });
      });
    },
  },

  mounted() {
    if (!this.route) {
      this.$router.push({ name: "RoutePlanner" });
      return;
    }

    this.displayMarkers();
    this.displayPolygons();
  },
});
