
import AccordionTab from "primevue/accordiontab";
import Accordion from "primevue/accordion";
import { defineComponent } from "vue";
import ChangeLanguage from "@/components/ChangeLanguage.vue";
import { InfoFeature } from "@/typings/Info";

export default defineComponent({
  data: () => ({
    wasteManagement: process.env.VUE_APP_WASTE_MANAGEMENT_ENABLE === "true",
    loyaltyEnabled: process.env.VUE_APP_LOYALTY_ENABLE === "true",
    bikesEnabled: process.env.VUE_APP_BIKES_ENABLE === "true",
    sharedMobilityEnabled:
      process.env.VUE_APP_SHARED_MOBILITY_ENABLE === "true",
    usesDirectus: process.env.VUE_APP_USES_DIRECTUS === "true",
    routePlannerEnabled: process.env.VUE_APP_ROUTE_PLANNER_ENABLE === "true",
  }),
  computed: {
    showNavigation() {
      return this.$store.getters["navigation/show"];
    },
    arrayOfFeatures(): { [key: string]: Array<InfoFeature> } {
      let featuresObject = {
        features: [] as InfoFeature[],
      } as { [key: string]: Array<InfoFeature> };

      const features = this.$store.getters["info-features/features"];

      if (!features) {
        return {};
      }

      for (let feature of features) {
        if (feature.group === null) {
          featuresObject["features"].push(feature);

          continue;
        }

        if (!featuresObject[feature.group]) {
          featuresObject[feature.group] = [];
        }

        featuresObject[feature.group].push(feature);
      }

      for (let key of Object.keys(featuresObject)) {
        featuresObject[key].sort((a, b) =>
          a.collection > b.collection ? 1 : -1,
        );
      }

      return featuresObject;
    },
  },
  mounted() {
    if (this.usesDirectus) {
      this.$store.dispatch("info-features/getFeatures");
    }
  },
  methods: {
    localize(feature: InfoFeature) {
      return feature[("name_" + this.$i18n.locale) as keyof InfoFeature];
    },
  },
  components: { ChangeLanguage, Accordion, AccordionTab },
});
