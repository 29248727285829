
import { defineComponent, PropType } from "vue";
import { Leg, Route } from "@/typings/RoutePlanner";
import IconBike from "~icons/tabler/bike";
import IconLock from "~icons/tabler/lock";

export default defineComponent({
  components: {
    IconBike,
    IconLock,
  },

  props: {
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
    leg: {
      type: Object as PropType<Leg>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  methods: {
    showBikeInfo(leg: Leg, index: number) {
      if (index === this.route.legs.length - 1) {
        return false;
      }

      if (!leg.start.bikeStation && leg.end.bikeStation) {
        return false;
      }

      return true;
    },
  },
});
