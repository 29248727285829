
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Password from "primevue/password";
import { RegistrationForm } from "@/typings/RegistrationForm";
import { RegistrationFormErrors } from "@/typings/RegistrationFormErrors";
import { firebaseLogClickEvent } from "@/plugins/firebase";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    consents: {
      terms: false,
      age: false,
    },

    form: {} as RegistrationForm,
    errors: {} as RegistrationFormErrors,
    regexEmail: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    regexPhone:
      /^(?:(?:(\+|00)\d{3}\s?)|(?:\d{3}))?(\s?\d{9}|\s?\d{3}(?:[\s-]?\d{3}){2}|\s?\d{2}(?:[\s-]?\d{3}){2})$/,
  }),
  props: {
    responseErrors: {
      type: Array as () => string[],
    },
  },
  mounted() {
    this.form;
  },
  methods: {
    backToHomePage() {
      this.$router.push({ name: "Login" });
    },
    checkValidPassword(password: string) {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
        password,
      );
    },
    submit() {
      firebaseLogClickEvent(
        "/register",
        "registerContinueToVerificationButton",
      );

      this.errors = {};
      if (!this.form.first_name) {
        this.errors.first_name = this.$t("error.enter_first_name");
      }
      if (!this.form.last_name) {
        this.errors.last_name = this.$t("error.enter_last_name");
      }
      if (!this.form.email) {
        this.errors.email = this.$t("error.enter_email");
      }
      if (!this.form.password || this.form.password.length < 6) {
        this.errors.password = this.$t("error.short_password");
      }
      if (
        !this.form.password ||
        this.form.password.length < 8 ||
        !this.checkValidPassword(this.form.password)
      ) {
        this.errors.password = this.$t("error.unvalid_password");
      }
      if (
        !this.form.password_confirmation ||
        this.form.password !== this.form.password_confirmation
      ) {
        this.errors.password_confirmation = this.$t(
          "error.passwords_dont_match",
        );
      }
      if (!this.regexEmail.test(this.form.email)) {
        this.errors.email = this.$t("error.enter_valid_email");
      }
      if (
        this.form.phone_number &&
        !this.regexPhone.test(this.form.phone_number)
      ) {
        this.errors.phone_number = this.$t("error.enter_valid_phone");
      }
      if (this.consents.terms !== true || this.consents.age !== true) {
        this.errors.consents = true;
        return;
      }

      const data = { ...this.form };

      if (data.phone_number) {
        data.phone_number = data.phone_number.replace(/[\s-]/g, "");
      }

      if (Object.keys(this.errors).length === 0) {
        firebaseLogOutComeEvent("/register", "registrationProcessFirstStep", {
          user_email: this.form.email,
          user_type: "registered",
          registration_type: "normal",
          process_step: 1,
        });
        this.$emit("submit", data);
      }
    },
  },

  components: { InputText, Password, InputSwitch },
});
