
import { defineComponent } from "vue";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import FormCheckbox from "@/components/customComponents/FormCheckbox.vue";
import InputTextWrapper from "@/components/customComponents/InputTextWrapper.vue";
import {
  Application,
  ApplicationErrors,
  Cadastral,
} from "@/typings/Application";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  props: {
    application: {
      required: true,
      type: Object as () => Application,
    },
  },
  data: () => ({
    showFillDialog: true,
    form: {
      name_and_surname: "",
      address: "",
      house_number: "",
      code: "",
      city: "",
      tax_number: "",
      phone_number: "",
      email: "",

      cadastral: [] as Array<Cadastral>,

      location_information: "",

      copy_of_graphic_part: false,
      spatial_implementation_conditions: false,

      delivery_email: true,
    } as Application,
    errors: {} as ApplicationErrors,
    addressSearch: null as null | string,
    validationInputs: {
      name_and_surname: "",
      address: "",
      house_number: "",
      code: "",
      city: "",
      tax_number: "",
      phone_number: "",
      email: "",
    } as ApplicationErrors,
    cadastralOptions: [
      { name: "Vrh (2007)", value: 2007 },
      { name: "Rovte (2008)", value: 2008 },
      { name: "Petkovec (2009)", value: 2009 },
      { name: "Žibrše (2010)", value: 2010 },
      { name: "Medvedje Brdo (2011)", value: 2011 },
      { name: "Novi Svet (2012)", value: 2012 },
      { name: "Hotedrščica (2013)", value: 2013 },
      { name: "Ravnik (2014)", value: 2014 },
      { name: "Gorenji Logatec (2015)", value: 2015 },
      { name: "Blekova vas (2016)", value: 2016 },
      { name: "Dolenji Logatec (2017)", value: 2017 },
      { name: "Laze (2018)", value: 2018 },
      { name: "Grčarevec(2019)", value: 2019 },
      { name: "Opale (2025)", value: 2025 },
      { name: "Jelični Vrh (2362)", value: 2362 },
      { name: "Log (2693)", value: 2693 },
      { name: "Hrušica (2703)", value: 2703 },
      { name: "Zaplana (2000)", value: 2000 },
    ],
    regexEmail: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    regexPhone:
      /^(?:(?:(\+|00)\d{3}\s?)|(?:\d{3}))?(\s?\d{9}|\s?\d{3}(?:[\s-]?\d{3}){2}|\s?\d{2}(?:[\s-]?\d{3}){2})$/,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  created() {
    firebaseLogOutComeEvent(
      "/user/*/new-application",
      "eApplicationSubmissionProcess",
      {
        process_step: 1,
        e_application_type: "Vloga za izdajo lokacijske informacije",
      },
    );
    this.form = this.application;
  },
  methods: {
    setAddress(place: GmapPlaceResult) {
      this.form.address =
        place.address_components.find((component) =>
          component.types.includes("route"),
        )?.long_name || "";
      this.form.house_number =
        place.address_components.find((component) =>
          component.types.includes("street_number"),
        )?.long_name || "";
      this.form.city =
        place.address_components.find(
          (component) =>
            component.types.includes("postal_town") ||
            component.types.includes("locality"),
        )?.long_name || "";
      this.form.code =
        place.address_components.find((component) =>
          component.types.includes("postal_code"),
        )?.long_name || "";
      this.addressSearch = null;
    },
    addCadastral() {
      this.form.cadastral.push({
        cadastral_municipality: this.form.cadastral[0].cadastral_municipality,
        plot_number: "",
      });
    },
    removeCadastral(index: number) {
      this.form.cadastral.splice(index, 1);
    },
    nextPage() {
      if (this.validate()) {
        this.$emit("nextPage", this.form);
      }
    },
    validate() {
      this.errors = {} as ApplicationErrors;
      Object.keys(this.validationInputs).map((el) => {
        if (!this.form[el as keyof Application]) {
          this.errors[el as keyof ApplicationErrors] =
            this.$t(`forms.application_forum.${el}`).toString() +
            " " +
            this.$t(`forms.application_forum.error`).toString();
        }
      });

      if (
        this.form.emso_number.trim().length === 0 &&
        this.form.registration_number.trim().length === 0
      ) {
        this.errors.emso_number = this.$t(
          "forms.application_forum.emso_missing_error",
        ).toString();
      }

      this.form.cadastral.forEach((cadastral) => {
        if (cadastral.cadastral_municipality === null) {
          this.errors.cadastral_municipality = this.$t(
            "forms.application_forum.cadastral_municipality_error",
          );
        }
        if (cadastral.plot_number.trim().length === 0) {
          this.errors.plot_number = this.$t(
            "forms.application_forum.plot_number_error",
          );
        }
      });

      if (this.form.location_information === "") {
        this.errors.location_information = this.$t(
          "forms.application_forum.location_information_error",
        ).toString();
      }

      if (this.regexEmail.test(this.form.email) === false) {
        this.errors.email = this.$t("error.enter_valid_email");
      }

      if (
        this.form.phone_number
          ? !this.regexPhone.test(this.form.phone_number)
          : false
      ) {
        this.errors.phone_number = this.$t("error.enter_valid_phone");
      }

      if (this.form.phone_number) {
        this.form.phone_number = this.form.phone_number.replace(/[\s-]/g, "");
      }

      if (Object.keys(this.errors).length !== 0) {
        (this.$refs.topForm as HTMLInputElement).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return false;
      }
      return true;
    },
    loadData() {
      this.form.name_and_surname =
        this.user.first_name + " " + this.user.last_name;
      this.form.address = this.user.contact_info.address;
      this.form.house_number = this.user.contact_info.house_number;
      this.form.code = this.user.contact_info.code;
      this.form.city = this.user.contact_info.city;
      this.form.email = this.user.email;
      this.form.phone_number = this.user.phone_number;

      this.showFillDialog = false;
    },
  },
  watch: {
    "form.location_information"(value: string) {
      if (value === "information_of_intented_use_of_space") {
        this.form.copy_of_graphic_part = false;
        this.form.spatial_implementation_conditions = false;
      }
    },
  },
  components: {
    InputText,
    InputTextWrapper,
    FormCheckbox,
    Button,
    Dropdown,
    Dialog,
  },
});
