
import { defineComponent } from "vue";
import Step1 from "@/components/eApplication/steps/Step1.vue";
import Step2 from "@/components/eApplication/steps/Step2.vue";
import Step3 from "@/components/eApplication/steps/Step3/index.vue";
import Step4 from "@/components/eApplication/steps/Confirmation.vue";
import { Application, Cadastral } from "@/typings/Application";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  props: {
    title: { type: String, default: "" },
    isValid: { type: Boolean, default: false },
    tempData: { type: Object },
  },
  data: () => ({
    step: 1,
    application: {
      name_and_surname: "",
      address: "",
      house_number: "",
      code: "",
      city: "",
      tax_number: "",
      registration_number: "",
      emso_number: "",
      phone_number: "",
      email: "",
      cadastral: [
        {
          cadastral_municipality: null,
          plot_number: "",
        },
      ] as Array<Cadastral>,

      location_information: "",

      copy_of_graphic_part: false,
      spatial_implementation_conditions: false,

      delivery_email: true,
    } as Application,
    error: "",
    paymentMethod: "",
  }),
  methods: {
    toStepTwo(data: Application) {
      this.application = data;
      this.step = 2;
    },
    toStepThree(paymentMethod: string) {
      this.paymentMethod = paymentMethod;

      // Go to confirmation if tax payment selected
      if (this.paymentMethod === "tax-confirmation") {
        this.submit(null);
        return;
      }

      this.step = 3;
    },
    submit(file: null | File) {
      this.application.form_id = +this.$route.params.id;

      const formData = new FormData();
      for (const [k, v] of Object.entries(this.application)) {
        if (Array.isArray(v)) {
          formData.append(k, JSON.stringify(v));
        } else {
          formData.append(k, v.toString());
        }
      }
      if (file !== null) {
        formData.append("administration_fee", file);
      }

      this.$http
        .post("api/v1/forms/submissions", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          firebaseLogOutComeEvent(
            "/user/*/new-application",
            "eApplicationSubmission",
            {
              form_id: this.application.form_id,
              e_application_type: "Vloga za izdajo lokacijske informacije",
            },
          );
          this.step = 4;
        })
        .catch(() => {
          this.error = this.$t("error.mistake");
        });
    },
    prevPage() {
      if (this.step > 1) {
        this.step--;
        return;
      }
    },
  },
  computed: {
    getTitle() {
      if (this.step === 1) return `forms.application_forum.title`;
      if (this.step === 2) return `forms.payment_methods.title`;
      if (this.step === 3) {
        if (this.paymentMethod === "tax-confirmation")
          return `forms.administrative_fee.title`;
        if (this.paymentMethod === "upn") return `forms.upn.title`;
        return `forms.administrative_fee.title_card`;
      }
      if (this.step === 4) return `forms.confirmation.title`;
      return `forms.application_forum.title`;
    },
  },
  components: { Step1, Step2, Step3, Step4 },
});
