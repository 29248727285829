import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "signInMenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("sign_up_registration.registered")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("sign_up_registration.visit")), 1),
    _createVNode(_component_CustomButton, {
      "event-name": "loginButtonClick",
      "event-from": "/",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pushRoute('Login'))),
      label: _ctx.$t('sign_up_registration.signup'),
      "add-class": "whiteBG"
    }, null, 8, ["label"])
  ]))
}