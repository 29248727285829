import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-536f0a8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-2xl font-semibold" }
const _hoisted_2 = { class: "gap-4 locations-grid" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-column gap-2 py-1 w-full" }
const _hoisted_5 = { class: "flex flex-column gap-1" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "text-sm text-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-column w-full gap-4 w-full", {
      'sm:w-9': _ctx.user,
    }])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("route_planner.popular")), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.popularLocations, (location) => {
        return (_openBlock(), _createElementBlock("div", {
          key: location.id,
          class: "flex border-1 border-200 border-round-2xl p-3 w-full gap-4"
        }, [
          (location.image_url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: location.image_url,
                alt: "",
                class: "border-round-2xl w-6rem h-6rem flex-shrink-0",
                style: {"object-fit":"cover"}
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(location.title), 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(location.address), 1)
            ]),
            _createVNode(_component_Button, {
              class: "button secondary small w-fit",
              label: _ctx.$t('global.go'),
              onClick: ($event: any) => (_ctx.selectLocation(location))
            }, null, 8, ["label", "onClick"])
          ])
        ]))
      }), 128))
    ])
  ], 2))
}