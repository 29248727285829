
import { defineComponent } from "vue";
import Map from "@/components/Map.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import Information from "@/components/parking/Information.vue";
import { Paginated } from "@/typings/Paginated";
import { Parking } from "@/typings/Parking";
import Button from "primevue/button";
import Divider from "primevue/divider";
import { QueryParameters } from "@/typings/QueryParameters";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import NearbyCard from "@/components/NearbyCard.vue";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  components: { Map, Button, Information, Divider, NearbyCard },
  data: () => ({
    parkings: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Parking>,
    selectedParking: null as null | Parking,
    selectedMarker: null as null | Marker,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    markers: [] as Marker[],
    zoom: 14,
    address: null,
    isLoading: false,
  }),
  computed: {
    nearestParkings() {
      return this.parkings.data.slice(0, 6);
    },
  },
  methods: {
    checkAvailability(parking: Parking) {
      const types = ["regular", "ev", "accessible"];
      const isSmartParking = parking.smart === 1;

      return types.every((typeValue) => {
        const type = `spaces_${typeValue}${isSmartParking ? "_available" : ""}`;
        return parking[type as keyof Parking] !== null;
      });
    },
    updateCenter(value: Coordinates) {
      this.center = value;

      this.loadParkings();
    },
    updateZoom(value: number, center: Coordinates) {
      this.zoom = value;
      this.center = center;

      this.loadParkings();
    },
    async loadParkings() {
      this.isLoading = true;
      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        perPage: 20,
        orderBy: {
          // Order by distance only when searching as it affects results
          distance: this.address !== null ? "ASC" : undefined,
        },
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 27 - this.zoom);
      }

      return this.$http
        .get("api/v2/parkings", { params })
        .then((response) => {
          this.parkings = response.data;

          this.setMarkers();
        })
        .finally(() => (this.isLoading = false));
    },
    loadSelectedParking() {
      if ("id" in this.$route.params === false) {
        this.unselectParking();
        return;
      }
      this.isLoading = true;
      this.$http
        .get("api/v2/parkings/" + this.$route.params.id)
        .then((response) => {
          this.selectedParking = response.data;

          if (!this.selectedParking) {
            return;
          }

          this.selectMarker(this.selectedParking);
          this.selectParking(this.selectedParking);
        })
        .catch(() => {
          this.$router.push({ name: "ParkingMap" });
        })
        .finally(() => {
          this.isLoading = false;

          this.loadParkings();
        });
    },
    setMarkers() {
      this.markers = this.parkings.data.map((parking) => ({
        id: parking.id,
        position: {
          lat: parseFloat(parking.latitude),
          lng: parseFloat(parking.longitude),
        },
        on_click_route_name: "ParkingById",
        type:
          parking.id === this.selectedMarker?.payload?.id
            ? "selected"
            : "parking",
        title: parking.name,
        icon: this.setMarkerIcon(parking),
        payload: parking,
      }));

      if (this.selectedMarker === null) {
        return;
      }

      const existingMarkerIndex = this.markers.findIndex(
        (marker) => marker.id === this.selectedMarker?.id,
      );

      if (existingMarkerIndex !== -1) {
        this.markers[existingMarkerIndex] = this.selectedMarker;
        return;
      }

      this.markers.push(this.selectedMarker);
    },
    selectMarker(parking: Parking) {
      if (this.selectedMarker?.payload?.id === parking.id) {
        return;
      }

      this.selectedMarker = {
        id: parking.id,
        position: {
          lat: parseFloat(parking.latitude),
          lng: parseFloat(parking.longitude),
        },
        on_click_route_name: "ParkingById",
        type: "selected",
        title: parking.name,
        icon: "/markers/parking-selected.svg",
        payload: parking,
      };

      if ("id" in this.$route.params && this.selectedParking) {
        this.selectParking(parking);
      }

      this.setMarkers();
    },
    unselectMarker() {
      this.selectedMarker = null;
      this.setMarkers();
    },
    selectParking(parking: Parking) {
      this.selectedParking = parking;

      this.selectMarker(parking);

      this.center = {
        lat: parseFloat(parking.latitude),
        lng: parseFloat(parking.longitude),
      };
      this.zoom = 16;

      this.$router.push({ name: "ParkingById", params: { id: parking.id } });
    },
    unselectParking() {
      this.selectedParking = null;

      this.zoom = 14;
      this.updateCenter(this.center);
    },
    setFavouriteParking(parking: Parking | null) {
      if (parking === null) {
        return;
      }

      if (parking.favourite === true) {
        this.$http
          .delete("api/v1/locations/" + parking.my_location_id)
          .then(() => {
            if ("id" in this.$route.params) {
              this.loadSelectedParking();
              return;
            }
            parking.favourite = false;
          });
        return;
      }

      this.$http
        .post("api/v1/locations", {
          type_id: 1,
          external_identifier: parking.id,
        })
        .then((response) => {
          firebaseLogOutComeEvent("/parking/map", "favoritesAdd");
          if ("id" in this.$route.params) {
            this.loadSelectedParking();
            return;
          }
          parking.favourite = true;
          parking.my_location_id = response.data.id;
        });
      return;
    },
    setAddress(place: GmapPlaceResult) {
      if (place.formatted_address === undefined) {
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.selectedParking = null;

      this.zoom = 17;
      this.updateCenter({
        lat: latitude,
        lng: longitude,
      });
    },
    // TODO: Handle markers with different SVG colors
    setMarkerIcon(parking: Parking) {
      if (parking.id === this.selectedParking?.id) {
        return "/markers/parking-selected.svg";
      }

      const totalAvailable = this.calculatePercentAvailability(parking);

      if (isNaN(totalAvailable) || totalAvailable < 0) {
        return "/markers/parking-grey.svg";
      }

      if (totalAvailable > 50) {
        return "/markers/parking-green.svg";
      } else if (totalAvailable >= 25) {
        return "/markers/parking-yellow.svg";
      } else if (totalAvailable >= 10) {
        return "/markers/parking-orange.svg";
      } else {
        return "/markers/parking-red.svg";
      }
    },
    totalAvailableSmartParkingSpaces(parking: Parking) {
      return (
        parking.spaces_regular -
        parking.spaces_regular_occupied +
        (parking.spaces_accessible - parking.spaces_accessible_occupied) +
        (parking.spaces_ev - parking.spaces_ev_occupied)
      );
    },

    totalAvailableParkingSpaces(parking: Parking) {
      return (
        parking.spaces_regular + parking.spaces_accessible + parking.spaces_ev
      );
    },
    calculatePercentAvailability(parking: Parking) {
      const totalFreeSpaces =
        parking.spaces_regular -
        parking.spaces_regular_occupied +
        parking.spaces_accessible -
        parking.spaces_accessible_occupied +
        parking.spaces_ev -
        parking.spaces_ev_occupied;

      const totalSpaces =
        parking.spaces_regular + parking.spaces_accessible + parking.spaces_ev;

      const totalAvailable = (totalFreeSpaces / totalSpaces) * 100;

      return totalAvailable;
    },
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.loadSelectedParking();
      },
      immediate: true,
    },
  },
});
