
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";
import Divider from "primevue/divider";
import AccordionTab from "primevue/accordiontab";
import Checkbox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import {
  TrashCan,
  CollectionSubscriber,
  WasteCollection,
  NotificationErrors,
  LocalComunityAddress,
} from "@/typings/WasteManagement";
import SidebarUserMenu from "@/components/user/SidebarUserMenu.vue";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import { Coordinates } from "@/typings/Marker";
import { QueryParameters } from "@/typings/QueryParameters";
import { firebaseInstance, firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  components: { Accordion, AccordionTab, Divider, Checkbox, Calendar, Dialog },
  data: () => ({
    isOldWasteRedirect:
      process.env.VUE_APP_OLD_WASTE_REDIRECT_ENABLE === "true",
    selectedSubscriberIndex: undefined as undefined | number,
    selectedSubscriber: {
      showSettings: false,
      time: "10:00",
      sms: false,
      email: false,
      push: false,
      collections: {} as WasteCollection,
    },
    errors: {} as NotificationErrors,
    addressError: "",
    showDeleteDialog: false,
    showFillDialog: false,
    searchResult: null as null | GmapPlaceResult,
    local: null as null | string,
    subscribers: [] as CollectionSubscriber[],
    sidebarComponent: SidebarUserMenu,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
  }),
  mounted() {
    this.getCollectionsSubscribers().then(() => this.checkUserAddress());
  },
  watch: {
    "selectedSubscriber.time"() {
      this.logfirebaseEvent();
    },
  },
  methods: {
    logfirebaseEvent() {
      firebaseLogOutComeEvent(
        "/user/my-private-waste/timeline",
        "notificationRegistrationProcessWaste",
        {
          notification_time: this.selectedSubscriber.time,
          sms_enabled: this.selectedSubscriber.sms,
          mail_enabled: this.selectedSubscriber.email,
          push_enabled: this.selectedSubscriber.push,
          process_step: 1,
        },
      );
    },
    isNotificationsActive(subscriber: CollectionSubscriber) {
      if (subscriber.sms || subscriber.email || subscriber.push) {
        return true;
      }

      return false;
    },
    openSubscriberCard(id: number) {
      this.selectedSubscriberIndex = id;

      if (id === undefined || id === null) {
        return;
      }

      this.getSubscribedWasteCollection(
        this.subscribers[id].local_community_id,
      );

      this.selectedSubscriber = {
        email: this.subscribers[id].email,
        sms: this.subscribers[id].sms,
        push: this.subscribers[id].push,
        time: this.subscribers[id].time,
        showSettings: false,
        collections: {} as WasteCollection,
      };
    },
    validate() {
      this.errors = {} as NotificationErrors;

      if (!this.selectedSubscriber.time) {
        this.errors.time = this.$t("waste_notification_error.time");
        return false;
      }

      return true;
    },
    setAddress(place: GmapPlaceResult) {
      this.addressError = "";
      this.local = place.formatted_address;
      this.searchResult = place;
    },
    async searchAddress(
      street: string,
      house_number: string,
      postCode: string,
    ) {
      return this.$http
        .post("api/v1/waste-management/local-communities/search", {
          street,
          house_number,
          postCode,
        })
        .then((response) => {
          if (response.data.total === 0) {
            // Display error if searching via autocomplete and nothing is found
            if (this.local !== null) {
              this.addressError = this.$t(
                "my_waste.no_address_found",
              ).toString();
            }
            return;
          }

          const address = response.data.data[0] as LocalComunityAddress;

          if (
            address.local_community_id === null ||
            address.local_community === null
          ) {
            this.addressError = this.$t("my_waste.no_address_found").toString();
            return;
          }

          return address;
        });
    },
    checkFoundAddress() {
      firebaseInstance?.analytics().logEvent("private_waste_screen", {
        page: "private_waste_screen",
        category: "private_waste_screen",
        category_desc: "Zaslon zasebnih odpadkov odprt",
        sub_category: "private_waste_add_pick_up_point",
        sub_category_desc:
          "Uporabnik je dodal prevzemno mesto za zasebne odpadke",
        ...this.$store.getters["user/analyticsInfo"],
      });
      // Address components from search
      let street = this.searchResult?.address_components.find(
        (component) =>
          component.types.includes("route") ||
          component.types.includes("locality"),
      )?.long_name;
      let house_number = this.searchResult?.address_components.find(
        (component) => component.types.includes("street_number"),
      )?.long_name;
      let postCode = this.searchResult?.address_components.find((component) =>
        component.types.includes("postal_code"),
      )?.long_name;

      if (
        house_number === undefined ||
        street === undefined ||
        postCode === undefined
      ) {
        this.addressError = this.$t("my_waste.full_address").toString();
        return;
      }

      this.searchAddress(street, house_number, postCode).then((address) => {
        if (!address) {
          return;
        }

        this.addToSubscribers(address);
      });
    },
    checkUserAddress() {
      if (
        this.subscribers.length > 0 ||
        !this.userAddress.street ||
        !this.userAddress.house_number ||
        !this.userAddress.post_code
      ) {
        return;
      }

      // Search for user address and display fill dialog
      this.searchAddress(
        this.userAddress.street,
        this.userAddress.house_number,
        this.userAddress.post_code,
      ).then((address) => {
        if (!address) {
          return;
        }

        this.showFillDialog = true;
      });
    },
    addUserAddress() {
      this.searchAddress(
        this.userAddress.street,
        this.userAddress.house_number,
        this.userAddress.post_code,
      ).then((address) => {
        if (!address) {
          return;
        }

        this.addToSubscribers(address).then(
          () => (this.showFillDialog = false),
        );
      });
    },
    async addToSubscribers(address: LocalComunityAddress) {
      return this.$http
        .post("api/v1/waste-management/collection-subscriber", {
          email: false,
          sms: false,
          push: false,
          time: "10:00",
          local_community_id: address.local_community_id,
          address_id: address.id,
        })
        .then(() => {
          this.local = null;
          this.getCollectionsSubscribers();
        });
    },
    updateNotifications(subscriber: CollectionSubscriber) {
      firebaseInstance?.analytics().logEvent("private_waste_screen", {
        page: "private_waste_screen",
        category: "private_waste_screen",
        category_desc: "Zaslon zasebnih odpadkov odprt",
        sub_category: "public_waste_add_phone_number",
        sub_category_desc:
          "Uporabnik je dodal prevzemno mesto za zasebne odpadke",
        ...this.$store.getters["user/analyticsInfo"],
      });
      if (!this.validate()) {
        return;
      }

      if (subscriber.time !== this.selectedSubscriber.time) {
        this.selectedSubscriber.time = this.$moment(
          this.selectedSubscriber.time,
        ).format("HH:mm");
      }

      firebaseInstance
        ?.analytics()
        .logEvent("waste_notification_subscription", {
          page: "waste_notification_subscription",
          category: "subscription_widget",
          sub_category: "confirm_settings",
          local_community_id: subscriber.local_community_id,
          email: this.selectedSubscriber.email,
          sms: this.selectedSubscriber.sms,
          push: this.selectedSubscriber.push,
          time: this.selectedSubscriber.time,
          ...this.$store.getters["user/user"].analyticsInfo,
        });

      this.$http
        .put(`api/v1/waste-management/collection-subscriber/${subscriber.id}`, {
          address_id: subscriber.address_id,
          local_community_id: subscriber.local_community_id,
          email: this.selectedSubscriber.email,
          sms: this.selectedSubscriber.sms,
          push: this.selectedSubscriber.push,
          time: this.selectedSubscriber.time,
        })
        .then(() => {
          this.selectedSubscriber.showSettings = false;
          firebaseLogOutComeEvent(
            "/user/my-private-waste/timeline",
            "wasteNotifications",
            {
              subscription_id: "", // Skomuniciraj s Primožom
              local_community_id: subscriber.local_community_id,
              notification_time: this.selectedSubscriber.time,
              sms_enabled: this.selectedSubscriber.sms,
              mail_enabled: this.selectedSubscriber.email,
              push_enabled: this.selectedSubscriber.push,
            },
          );
          firebaseLogOutComeEvent(
            "/user/my-private-waste/timeline",
            "notificationRegistrationWaste",
            {
              subscription_id: "", // Skomuniciraj s Primožom
              local_community_id: subscriber.local_community_id,
              notification_time: this.selectedSubscriber.time,
              sms_enabled: this.selectedSubscriber.sms,
              mail_enabled: this.selectedSubscriber.email,
              push_enabled: this.selectedSubscriber.push,
            },
          );
          this.errors = {} as NotificationErrors;
          if (this.errors !== null) {
            firebaseInstance
              ?.analytics()
              .logEvent("subscribed_to_my_waste_success");
          }
          this.getCollectionsSubscribers();
        });
    },
    removeSubscription(subscriberIndex: number) {
      const subscriber = this.subscribers[subscriberIndex];

      this.$http
        .delete(
          `api/v1/waste-management/collection-subscriber/${subscriber.id}`,
        )
        .finally(() => {
          this.showDeleteDialog = false;
          this.selectedSubscriberIndex = undefined;
          this.getCollectionsSubscribers();
        });
    },
    cancelNotificationSettings() {
      if (this.selectedSubscriberIndex === undefined) {
        return;
      }

      this.selectedSubscriber.showSettings = false;
      this.selectedSubscriber.email =
        this.subscribers[this.selectedSubscriberIndex].email;
      this.selectedSubscriber.sms =
        this.subscribers[this.selectedSubscriberIndex].sms;
      this.selectedSubscriber.push =
        this.subscribers[this.selectedSubscriberIndex].push;
      this.selectedSubscriber.time =
        this.subscribers[this.selectedSubscriberIndex].time;
    },
    async getCollectionsSubscribers() {
      let params = {
        filters: {
          citizen_id: this.user.id,
        },
      } as QueryParameters;
      return this.$http
        .get("api/v1/waste-management/collection-subscriber", {
          params,
        })
        .then((response) => {
          this.subscribers = response.data.data;
        });
    },
    getSubscribedWasteCollection(id: number) {
      let params = {
        filters: {
          local_community_id: id,
        },
        orderBy: {
          start_time: "ASC",
        },
        skipExpired: true,
        groupByDate: true,
      };
      this.$http
        .get("api/v1/waste-management/waste-collections", { params })
        .then((response) => {
          this.selectedSubscriber.collections = response.data;
        });
    },
    getTrashcanValue(waste_type: string, type: string) {
      let trashcan = this.trashCans.find(
        (trashCan) => trashCan.name === waste_type,
      ) as TrashCan;

      return trashcan ? trashcan[type as keyof TrashCan] : "";
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    trashCans(): TrashCan[] {
      return [
        {
          name: "organic",
          title: this.$t("waste.organic_trash"),
          icon: "icon-warpit_icon_waste_organic",
        },
        {
          name: "other",
          title: this.$t("waste.other_trash"),
          icon: "icon-warpit_icon_waste_other",
        },
        {
          name: "plastic",
          title: this.$t("waste.packaging_trash"),
          icon: "icon-warpit_icon_waste_packaging",
        },
        {
          name: "glass",
          title: this.$t("waste.glass_trash"),
          icon: "icon-warpit_icon_waste_glass",
        },
        {
          name: "paper",
          title: this.$t("waste.paper_trash"),
          icon: "icon-warpit_icon_waste_paper",
        },
        {
          name: "oil",
          title: this.$t("waste.oil_trash"),
          icon: "icon-warpit_icon_oil",
        },
        {
          name: "textile",
          title: this.$t("waste.textile_trash"),
          icon: "icon-warpit_icon_chlotes",
        },
        {
          name: "batteries",
          title: this.$t("waste.batteries_trash"),
          icon: "icon-warpit_icon_waste_paper",
        },
        {
          name: "WEEE",
          title: this.$t("waste.weee_trash"),
          icon: "icon-warpit_icon_phone_broken",
        },
      ];
    },
    userAddress() {
      let address = {} as LocalComunityAddress;

      if (this.user.contact_info && this.user.contact_info.address !== null) {
        address.street = this.user.contact_info.address;
      }
      if (
        this.user.contact_info &&
        this.user.contact_info.house_number !== null
      ) {
        address.house_number = this.user.contact_info.house_number;
      }
      if (this.user.contact_info && this.user.contact_info.code !== null) {
        address.post_code = this.user.contact_info.code;
      }

      return address;
    },
  },
});
