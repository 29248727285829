import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68f0aead"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "new-application-box"
}
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.application)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.application.name), 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.application.description), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            "event-name": "submitApplicationButton",
            "event-from": "/user/new-applications",
            class: "defaultButton",
            label: _ctx.$t('forms.new_form.submit_form'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.$router.push({
            name: 'NewApplication',
            params: { id: _ctx.application.id },
          })
        ))
          }, null, 8, ["label"])
        ])
      ]))
    : _createCommentVNode("", true)
}