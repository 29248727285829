
import { defineComponent } from "vue";
import ProgressBar from "primevue/progressbar";
import { InterviewerSurvey } from "@/typings/InterviewerSurvey";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    surveys: [] as InterviewerSurvey[],
  }),
  mounted() {
    this.loadSurveys();
  },
  methods: {
    loadSurveys() {
      this.$http.get("api/v1/surveys").then((response) => {
        this.surveys = response.data.filter((survey: InterviewerSurvey) => {
          return survey.qStatus !== 1;
        });
      });
    },
    startSurvey(index: number) {
      const survey = this.surveys[index];
      let link = survey.url;
      if (link === null) {
        return;
      }

      const surveyName =
        survey.browser_title || survey.long_project_name || survey.name;

      firebaseLogOutComeEvent("/survey/list/active", "surveySubmisionProcess", {
        process_step: 1,
        survey_name: surveyName,
      });

      window.open(link, "_blank");
    },
  },
  components: {
    ProgressBar,
  },
});
