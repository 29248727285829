
import { defineComponent } from "vue";
import IconMoodSad from "~icons/tabler/mood-sad";
import IconMoodConfuzed from "~icons/tabler/mood-confuzed";
import IconMoodEmpty from "~icons/tabler/mood-empty";
import IconMoodSmile from "~icons/tabler/mood-smile";
import IconMoodSmileBeam from "~icons/tabler/mood-smile-beam";
import RadioButton from "primevue/radiobutton";
import { useCssVar } from "@vueuse/core";

const __default__ = defineComponent({
  components: {
    IconMoodEmpty,
    RadioButton,
  },

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const redColor = useCssVar("--red-500");
    const orangeColor = useCssVar("--orange-500");
    const primaryColor = useCssVar("--primary-500");
    return { redColor, orangeColor, primaryColor };
  },

  computed: {
    effortOptions() {
      return [
        {
          value: 100,
          label: this.$t("route_planner.effort.minimal"),
          icon: IconMoodSad,
          color: this.redColor,
        },
        {
          value: 50,
          label: this.$t("route_planner.effort.low"),
          icon: IconMoodConfuzed,
          color: this.orangeColor,
        },
        {
          value: 0,
          label: this.$t("route_planner.effort.neutral"),
          icon: IconMoodEmpty,
          color: this.primaryColor,
        },
        {
          value: -50,
          label: this.$t("route_planner.effort.moderate"),
          icon: IconMoodSmile,
          color: this.primaryColor,
        },
        {
          value: -100,
          label: this.$t("route_planner.effort.high"),
          icon: IconMoodSmileBeam,
          color: this.primaryColor,
        },
      ];
    },
    selectedEffort: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
    valueColor() {
      return this.effortOptions.find(
        (option) => option.value === this.selectedEffort,
      )?.color;
    },
  },

  methods: {
    getOptionIcon(value: number) {
      const icon = this.effortOptions.find(
        (option) => option.value === value,
      )?.icon;

      return icon ? icon : IconMoodEmpty;
    },
    getOptionLabel(value: number) {
      return this.effortOptions.find((option) => option.value === value)?.label;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2a493bc0": (_ctx.valueColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__