
import { defineComponent } from "vue";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import { firebaseInstance, firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    isLoading: false,
    showSettings: false,
    subscription: {
      sms: false,
      push: false,
      email: false,
    },
    selection: {
      sms: false,
      push: false,
      email: false,
    },
    showUnsubscribeDialog: false,
  }),
  mounted() {
    if (!this.user) {
      this.$router.push({ name: "Weather" });
      return;
    }

    this.getSubscription();
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    isNotificationsActive() {
      if (
        this.subscription.sms ||
        this.subscription.email ||
        this.subscription.push
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    changeSelection() {
      firebaseLogOutComeEvent(
        "/weather/alerts",
        "environmentAlertsNotificationRegistrationProcess",
        {
          sms_enabled: this.selection.sms,
          mail_enabled: this.selection.email,
          push_enabled: this.selection.push,
          process_step: 1,
        },
      );
    },
    async getSubscription() {
      this.isLoading = true;

      return this.$http
        .get("api/v1/alert-subscribers", {
          params: {
            filters: {
              citizen_id: this.user.id,
            },
          },
        })
        .then((response) => {
          if (response && response.data.total > 0) {
            this.subscription = response.data.data[0];

            this.selection = { ...this.subscription };

            if (this.isNotificationsActive) {
              this.showSettings = false;
            }
          }
        })
        .finally(() => (this.isLoading = false));
    },
    async updateSubscription() {
      return this.$http
        .post("api/v1/alert-subscribers", this.selection)
        .then(() => {
          this.getSubscription();
        });
    },
    saveSubscription() {
      if (
        this.isNotificationsActive &&
        !this.showUnsubscribeDialog &&
        !this.selection.sms &&
        !this.selection.push &&
        !this.selection.email
      ) {
        this.showUnsubscribeDialog = true;
        return;
      }

      this.updateSubscription().then(() => {
        if (this.showUnsubscribeDialog) {
          this.showUnsubscribeDialog = false;
          this.$router.push({ name: "Weather" });
          return;
        }

        firebaseLogOutComeEvent(
          "/weather/alerts",
          "notificationRegistrationWeather",
          {
            sms_enabled: this.selection.sms,
            mail_enabled: this.selection.email,
            push_enabled: this.selection.push,
          },
        );
        firebaseLogOutComeEvent(
          "/weather/alerts",
          "environmentAlertsNotificationSub",
          {
            sms_enabled: this.selection.sms,
            mail_enabled: this.selection.email,
            push_enabled: this.selection.push,
          },
        );
      });
    },
    cancelSettings() {
      if (!this.isNotificationsActive) {
        this.$router.push({ name: "Weather" });
      }

      this.selection = { ...this.subscription };

      this.showSettings = false;
    },
  },
  components: { Checkbox, Dialog },
});
