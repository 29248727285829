
import { defineComponent } from "vue";
import Step1 from "@/components/user/myPayments/steps/Step1.vue";
import Step2 from "@/components/user/myPayments/steps/Step2.vue";
import Step3 from "@/components/user/myPayments/steps/Step3.vue";
import { Invoice } from "@/typings/Invoice";
import { Card } from "@/typings/Card";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    step: 1,
    invoice: null as Invoice | null,
    selectedPaymentMethod: "card",
    selectedCard: null as Card | null,
    cardForm: null as {
      credit_card_token: string;
      credit_card_store: boolean;
      credit_card_custom_name: string;
    } | null,
  }),
  mounted() {
    if (this.$route.query.method) {
      this.step = 2;
    }
    this.loadInvoice();
  },
  methods: {
    loadInvoice() {
      this.$http
        .get("/api/v1/invoices/" + this.$route.params.id)
        .then((res) => {
          this.invoice = res.data;
        });
    },
    selectPaymentMethod(method: string, card: Card | null) {
      this.selectedPaymentMethod = method;
      this.selectedCard = card;
      if (this.selectedPaymentMethod === "card" && this.selectedCard === null) {
        this.step = 2;
        return;
      }
      this.step = 3;
    },
    storeCardForm({
      token,
      saveCard,
      customName,
    }: {
      token: string;
      saveCard: boolean;
      customName: string;
    }) {
      this.cardForm = {
        credit_card_token: token,
        credit_card_store: saveCard,
        credit_card_custom_name: customName,
      };
      this.step = 3;
    },
    payment() {
      switch (this.selectedPaymentMethod) {
        case "card":
          this.processDebitPayment();
          break;
        case "flik":
          this.processFlikPayment();
          break;
        default:
          this.toStepOne();
      }
    },
    processDebitPayment() {
      let params = {};

      if (
        (this.selectedCard === null && this.cardForm === null) ||
        (this.selectedCard !== null && this.cardForm !== null)
      ) {
        this.toStepOne();
        return;
      }

      if (this.selectedCard !== null) {
        params = {
          credit_card_id: this.selectedCard.id,
        };
      }

      if (this.cardForm !== null) {
        params = { ...this.cardForm };
      }

      this.$http
        .post("/api/v1/invoices/" + this.$route.params.id + "/debit", params)
        .then((response) => {
          if ("redirect_url" in response.data) {
            window.location.href = response.data.redirect_url;
            firebaseLogOutComeEvent(
              "/user/my-payments/invoices/*/payment",
              "payment",
              {
                payment_value: "null",
                service_type: "null",
              }
            );
            return;
          }
          this.$router.push({ name: "PaymentOpen" });
        })
        .catch(() => {
          this.$router.push({ name: "PaymentOpen", query: { error: 1 } });
        });
    },
    processFlikPayment() {
      this.$http
        .post("/api/v1/invoices/" + this.$route.params.id + "/flik")
        .then((response) => {
          if ("redirect_url" in response.data) {
            window.location.href = response.data.redirect_url;
            return;
          }
          this.$router.push({ name: "PaymentOpen" });
        })
        .catch(() => {
          this.$router.push({ name: "PaymentOpen", query: { error: 1 } });
        });
    },
    toStepOne() {
      this.selectedCard = null;
      this.cardForm = null;
      this.step = 1;
    },
  },
  components: { Step3, Step2, Step1 },
});
