
import { defineComponent } from "vue";
import Button from "primevue/button";
import { FavouriteLocation } from "@/typings/RoutePlanner";

export default defineComponent({
  components: { Button },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  data: () => ({
    popularLocations: null as null | FavouriteLocation[],
  }),
  methods: {
    async getPopularLocations() {
      try {
        const response = await this.$maas.get("api/v1/popular-locations");

        this.popularLocations = response.data.data;
      } catch (error) {
        //
      }
    },
    selectLocation(location: FavouriteLocation) {
      this.$store.dispatch("route-planner/setSelectedLocation", location);
    },
  },
  async mounted() {
    await this.getPopularLocations();
  },
});
