import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapInfoWindow = _resolveComponent("GMapInfoWindow")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapPolyline = _resolveComponent("GMapPolyline")!
  const _component_GMapPolygon = _resolveComponent("GMapPolygon")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createBlock(_component_GMapMap, {
    ref: "mapRef",
    options: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      clickableIcons: false,
    },
    center: _ctx.center,
    zoom: _ctx.zoom,
    onZoom_changed: _ctx.updateZoom,
    onCenter_changed: _ctx.updateTemporaryCenter,
    onDragend: _ctx.updateCenter
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (marker) => {
        return (_openBlock(), _createBlock(_component_GMapMarker, {
          key: marker.id,
          position: marker.position,
          clickable: true,
          draggable: false,
          icon: marker.icon,
          onMouseover: ($event: any) => (_ctx.hoverOpen(marker)),
          onMouseout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hoveredMarker = null)),
          onClick: ($event: any) => (_ctx.openInfo(marker))
        }, {
          default: _withCtx(() => [
            (marker.title !== null)
              ? (_openBlock(), _createBlock(_component_GMapInfoWindow, {
                  key: 0,
                  opened: 
          (_ctx.hoveredMarker && _ctx.hoveredMarker.id === marker.id) ||
          marker.selected ||
          marker.type === 'selected'
        
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "info", {
                      payload: marker.payload
                    }, () => [
                      _createElementVNode("div", null, [
                        _createElementVNode("strong", null, _toDisplayString(marker.title), 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["opened"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["position", "icon", "onMouseover", "onClick"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.geoJson, (item, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          (item.geometry.type === 'LineString')
            ? (_openBlock(), _createBlock(_component_GMapPolyline, {
                key: 0,
                path: 
          item.geometry.geometryString
            ? _ctx.decodePath(item.geometry.geometryString)
            : item.path
        ,
                options: {
          strokeColor: item.color,
          strokeWeight: item.properties?.strokeWidth || 10,
          strokeOpacity: item.properties?.isDashed ? 0 : 1,
          icons: _ctx.getPolylineIcons(item.properties?.isDashed as boolean),
        },
                onClick: ($event: any) => (_ctx.openInfoWindow(item))
              }, null, 8, ["path", "options", "onClick"]))
            : _createCommentVNode("", true),
          (item.geometry.type === 'Polygon')
            ? (_openBlock(), _createBlock(_component_GMapPolygon, {
                key: index,
                paths: item.path,
                options: {
          strokeColor: item.color,
          zIndex: 0,
        },
                onClick: ($event: any) => (_ctx.openInfoWindow(item))
              }, null, 8, ["paths", "options", "onClick"]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (_ctx.selectedGeoJson)
        ? (_openBlock(), _createBlock(_component_GMapInfoWindow, {
            key: 0,
            position: _ctx.infoWindowPosition,
            opened: _ctx.selectedGeoJson && _ctx.selectedGeoJson.selected,
            options: { maxWidth: '600', width: '500px' }
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "geoJsonInfo", {
                payload: _ctx.selectedGeoJson.payload
              })
            ]),
            _: 3
          }, 8, ["position", "opened"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["center", "zoom", "onZoom_changed", "onCenter_changed", "onDragend"]))
}