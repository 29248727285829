
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Widget } from "@/typings/Widget";
import WeatherWidget from "./widgets/WeatherWidget.vue";
import AirQualityWidget from "./widgets/AirQualityWidget.vue";
import TransportWidget from "./widgets/TransportWidget.vue";
import EventsWidget from "./widgets/EventsWidget.vue";
import WasteWidget from "./widgets/WasteWidget.vue";
import TrafficWidget from "./widgets/TrafficWidget.vue";
import RoutePlannerWidget from "./widgets/RoutePlannerWidget.vue";
import Dialog from "primevue/dialog";
import AddWidget from "./AddWidget.vue";
import EditWidget from "./EditWidget.vue";

import "swiper/swiper-bundle.css";

export default defineComponent({
  data: () => ({
    widgets: null as null | Array<Widget>,
    showAddWidget: false,
    selectedWidget: null as null | Widget,
    showEditWidget: false,
    isMobile: window.innerWidth <= 760,
    latitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
    longitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    isRoutePlannerEnabled: process.env.VUE_APP_ROUTE_PLANNER_ENABLE === "true",
  }),
  methods: {
    loadWidgets() {
      const params = {
        latitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        longitude: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
        radius: 20000,
      };
      this.$http
        .get("api/v1/dashboard/widgets", { params })
        .then((response) => {
          this.widgets = response.data;
        });
    },
    editWidget(widget: Widget) {
      this.selectedWidget = widget;
      this.showEditWidget = true;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 760;
    },
  },
  computed: {
    filteredWidgets() {
      let filteredWidgets = [] as Widget[];

      this.widgets?.forEach((widget) => {
        if (
          widget.type === "waste-collection" &&
          widget.external_data === null
        ) {
          return;
        }

        filteredWidgets.push(widget);
      });

      return filteredWidgets;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    components() {
      return {
        weather: WeatherWidget,
        "air-quality": AirQualityWidget,
        "public-transport": TransportWidget,
        events: EventsWidget,
        "waste-collection": WasteWidget,
        "traffic-info": TrafficWidget,
      };
    },
  },
  mounted() {
    this.loadWidgets();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: {
    Swiper,
    SwiperSlide,
    WeatherWidget,
    Dialog,
    AddWidget,
    EditWidget,
    RoutePlannerWidget,
  },
});
