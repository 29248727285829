
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { WeatherModel, CurrentWeatherModel } from "@/models/weather/Weather";
import { defineComponent } from "vue";
import {
  CurrentWeather as CurrentWeatherInterface,
  Weather as WeatherInterface,
} from "@/typings/Weather";

import WeatherChart from "@/components/charts/WeatherChart.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination } from "swiper";
import { firebaseInstance } from "@/plugins/firebase";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import AlertsWidget from "@/components/weather/AlertsWidget.vue";

export default defineComponent({
  data: () => ({
    modules: [Pagination],
    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
      650: {
        slidesPerView: 6,
        spaceBetween: 0,
      },
      1000: {
        slidesPerView: 13,
        spaceBetween: 0,
      },
    },

    currently: null as CurrentWeatherInterface | null,
    daily: {
      data: [],
    },
    alertSubscription: null as null | {
      sms: boolean;
      push: boolean;
      email: boolean;
    },
    isLoadingAlerts: true,
  }),
  mounted() {
    this.loadWeather();

    this.getAlertSubscription();
  },
  methods: {
    handleAlertsNavigation() {
      const targetRoute = this.user ? "WeatherAlerts" : "Login";

      this.$router.push({ name: targetRoute });
    },
    handleAccordionChange() {
      firebaseInstance?.analytics().logEvent("weather_screen", {
        page: "weather_screen",
        category: "weather_screen",
        category_desc: "Uporabnik odprl zaslon za vreme",
        sub_category: "weather_accordion_expanded",
        sub_category_desc: "Uporabnik je razširil vremensko harmoniko",
        ...this.$store.getters["user/analyticsInfo"],
      });
    },
    loadWeather() {
      this.$http.get("api/v1/weather").then((response) => {
        this.currently = new CurrentWeatherModel(response.data.currently);
        this.daily.data = response.data.daily.data.map(
          (dailyData: WeatherInterface) => {
            let model = new WeatherModel(dailyData);
            model.filterHourly(response.data.hourly.data);
            return model;
          },
        );
        this.loadAnimation(this.currently.icon);
      });
    },
    async getAlertSubscription() {
      if (!this.user) {
        return;
      }

      return this.$http
        .get("api/v1/alert-subscribers", {
          params: {
            filters: {
              citizen_id: this.user.id,
            },
          },
        })
        .then((response) => {
          if (response && response.data.total > 0) {
            this.alertSubscription = response.data.data[0];
          }
        })
        .finally(() => {
          this.isLoadingAlerts = false;
        });
    },
    loadAnimation(icon: string) {
      const canvas = this.$refs["canvas"];

      // eslint-disable-next-line
      const rive = (window as any).rive;

      new rive.Rive({
        src: "/rive/" + icon + ".riv",
        canvas,
        autoplay: true,
        animations: [], // animation type
        layout: new rive.Layout({
          fit: "cover",
          alignment: "center",
        }),
      });
    },
    currentTimeInPercents(sunriseTime: number, sunsetTime: number) {
      let sunrise = this.$moment.unix(sunriseTime);
      let sunset = this.$moment.unix(sunsetTime);
      let currentTime = sunrise.clone().set({
        hour: this.$moment().get("hour"),
        minute: this.$moment().get("minute"),
      });
      let minutesInDay = 24 * 60;

      let minutesBetweenMidnightAndSunrise = sunrise.diff(
        sunrise.clone().startOf("day"),
        "minutes",
      );

      if (currentTime < sunrise) {
        return (
          150 +
          (currentTime.diff(sunrise.clone().startOf("day"), "minutes") * 50) /
            minutesBetweenMidnightAndSunrise
        );
      }
      if (currentTime > sunset) {
        let minutesBetweenSunsetAndMidnight = sunset.diff(
          sunrise.clone().endOf("day"),
          "minutes",
        );
        return (
          150 -
          (currentTime.diff(sunrise.clone().endOf("day"), "minutes") * 50) /
            minutesBetweenSunsetAndMidnight
        );
      }

      let minutesBetweenSunriseAndSunset = sunset.diff(sunrise, "minutes");

      let offset = Math.round(
        (minutesBetweenMidnightAndSunrise / minutesInDay) * 100 * 2,
      );

      return (
        (currentTime.diff(sunrise.clone().startOf("day"), "minutes") * 100) /
          minutesBetweenSunriseAndSunset -
        offset
      );
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    isSubscribedToAlerts() {
      if (
        this.alertSubscription &&
        (this.alertSubscription.sms ||
          this.alertSubscription.email ||
          this.alertSubscription.push)
      ) {
        return true;
      }

      return false;
    },
    alertsText() {
      if (!this.user || !this.isSubscribedToAlerts) {
        return this.$t("weather.alerts.activate");
      }

      return this.$t("weather.alerts.alerts_active");
    },
  },
  components: {
    WeatherChart,
    Accordion,
    AccordionTab,
    Swiper,
    SwiperSlide,
    AlertsWidget,
  },
});
