
import { defineComponent } from "vue";
import OrderForm from "@/components/wasteManagement/takeaway/OrderForm.vue";
import Overview from "@/components/wasteManagement/takeaway/Overview.vue";
import Confirmation from "@/components/wasteManagement/takeaway/Confirmation.vue";
import Dialog from "primevue/dialog";
import { WasteTakeAwayForm } from "@/typings/WasteManagement";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";
export default defineComponent({
  components: { OrderForm, Overview, Confirmation, Dialog },
  data: () => ({
    step: 1,
    form: {
      citizen_id: "",
      client_number: "",
      client_name: "",
      address: "",
      personal_id_number: "",
      phone_number: "",
      email: "",
      notes: "",
      waste_type_quantity: {
        furniture: 0,
        electronic: 0,
        mattresses: 0,
        carpets: 0,
        sports_equipment: 0,
        garden_machinery: 0,
        stoves_cookers_radiators: 0,
        iron: 0,
      },
    } as WasteTakeAwayForm,
    successful: false,
    leaveConfirmDialog: false,
    leaveRoutePath: null as null | string,
    showFillDialog: true,
  }),
  mounted() {
    firebaseLogOutComeEvent(
      "/user/my-private-waste/takeaway",
      "wasteRegistrationProcess",
      {
        process_step: 1,
      },
    );
  },
  watch: {
    step() {
      firebaseLogOutComeEvent(
        "/user/my-private-waste/takeaway",
        "wasteRegistrationProcess",
        {
          process_step: this.step,
        },
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.step > 2 || this.leaveRoutePath !== null) {
      next();
      return;
    }

    this.leaveRoutePath = to.path;

    this.leaveConfirmDialog = true;

    next(false);
  },
  methods: {
    fillForm() {
      this.form.client_name = `${this.user.first_name} ${this.user.last_name}`;
      if (this.user.contact_info && this.user.contact_info.address !== null) {
        this.form.address = `${this.user.contact_info.address} `;
      }
      if (
        this.user.contact_info &&
        this.user.contact_info.house_number !== null
      ) {
        this.form.address += `${this.user.contact_info.house_number} `;
      }
      if (this.user.contact_info && this.user.contact_info.city !== null) {
        this.form.address += `${this.user.contact_info.city}`;
      }
      this.form.phone_number = this.user.phone_number;
      this.form.email = this.user.email;
      this.showFillDialog = false;
    },
    setAddress(place: GmapPlaceResult) {
      this.form.address = place.formatted_address;
    },
    submitForm() {
      this.form.citizen_id = this.user.id;
      this.$http
        .post("api/v1/waste-management/collection-order", this.form)
        .then(() => {
          firebaseLogOutComeEvent(
            "/user/my-private-waste/takeaway",
            "wasteRegistration",
          );
          this.successful = true;
          this.step = 3;
        })
        .catch(() => {
          this.successful = false;
          this.step = 3;
        });
    },
    toStepTwo(data: WasteTakeAwayForm) {
      this.form = data;
      this.step = 2;
    },
    leaveRoute(confirm: boolean) {
      if (confirm && this.leaveRoutePath !== null) {
        this.leaveConfirmDialog = false;
        this.$router.push(this.leaveRoutePath);
        return;
      }

      this.leaveConfirmDialog = false;
      this.leaveRoutePath = null;
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
