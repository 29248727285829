
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import { ForgotPasswordErrors } from "@/typings/ForgottenPasswordForm";
import { firebaseLogClickEvent } from "@/plugins/firebase";

export default defineComponent({
  components: { InputText },
  data: () => ({
    token: "",
    errors: {} as ForgotPasswordErrors,
  }),
  props: {
    username: String,
  },
  methods: {
    validateToken() {
      this.checkForm();
      if (Object.keys(this.errors).length !== 0) {
        return;
      }
      this.$http
        .post("/api/v1/password-reset/" + this.token + "/validate", {
          username: this.username,
        })
        .then(() => {
          firebaseLogClickEvent("/forgot-password", "forgottenPasswordButton");
          this.$emit("updateToken", this.token);
        })
        .catch((error) => {
          if (error.response.data.message === "Invalid token") {
            this.errors.token = this.$t(`error.wrong_passcode`);
            return;
          }
          this.errors.something_wrong = this.$t(`error.mistake`);
        });
    },
    checkForm() {
      this.errors = {};
      if (this.token === "") {
        this.errors.token = this.$t(`error.enter_passcode`);
      }
    },
  },
});
