import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-934c34d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-column gap-3" }
const _hoisted_2 = { class: "flex align-items-center justify-content-between px-4" }
const _hoisted_3 = { class: "font-semibold text-lg" }
const _hoisted_4 = { class: "flex flex-column effort-options px-4 sm:flex-row" }
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t("route_planner.effort.title")) + ": ", 1),
        _createElementVNode("span", {
          style: _normalizeStyle({ color: _ctx.valueColor })
        }, _toDisplayString(_ctx.getOptionLabel(_ctx.selectedEffort)), 5)
      ]),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getOptionIcon(_ctx.selectedEffort)), {
        class: "thin-icon text-2xl",
        style: _normalizeStyle({ color: _ctx.valueColor })
      }, null, 8, ["style"]))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.effortOptions, (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["flex flex-column align-items-center gap-2 border-1 border-300 p-3 w-full", {
          'border-round-top-xl sm:border-noround-top sm:border-round-left-xl':
            index === 0,
          'border-round-bottom-xl sm:border-noround-bottom sm:border-round-right-xl':
            index === _ctx.effortOptions.length - 1,
        }])
        }, [
          _createVNode(_component_RadioButton, {
            modelValue: _ctx.selectedEffort,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedEffort) = $event)),
            name: "effort",
            value: option.value,
            inputId: option.label
          }, null, 8, ["modelValue", "value", "inputId"]),
          _createElementVNode("label", {
            for: option.label,
            class: _normalizeClass(["text-base text-600 font-medium", {
            'text-900 font-semibold': _ctx.selectedEffort === option.value,
          }])
          }, _toDisplayString(option.label), 11, _hoisted_5)
        ], 2))
      }), 128))
    ])
  ]))
}