
import { defineComponent, PropType } from "vue";
import { Leg, Step } from "@/typings/RoutePlanner";
import RouteDetailsHeader from "@/components/routePlanner/RouteDetailsHeader.vue";
import Map from "@/components/Map.vue";
import Button from "primevue/button";
import IconWalk from "~icons/tabler/walk";
import IconBike from "~icons/tabler/bike";
import IconCar from "~icons/tabler/car";
import IconMapPin from "~icons/tabler/map-pin";
import IconFlag from "~icons/tabler/flag";
import IconArrowUp from "~icons/tabler/arrow-up";
import IconArrowBearLeft from "~icons/tabler/arrow-bear-left";
import IconArrowBearRight from "~icons/tabler/arrow-bear-right";
import IconArrowLeft from "~icons/tabler/arrow-left";
import IconArrowRight from "~icons/tabler/arrow-right";
import IconArrowSharpTurnLeft from "~icons/tabler/arrow-sharp-turn-left";
import IconArrowSharpTurnRight from "~icons/tabler/arrow-sharp-turn-right";
import IconArrowRoundaboutLeft from "~icons/tabler/arrow-roundabout-left";
import IconElevator from "~icons/tabler/elevator";

export default defineComponent({
  components: {
    RouteDetailsHeader,
    Map,
    Button,
    IconWalk,
    IconBike,
    IconCar,
    IconFlag,
    IconMapPin,
    IconArrowUp,
    IconArrowBearLeft,
    IconArrowBearRight,
    IconArrowLeft,
    IconArrowRight,
    IconArrowSharpTurnLeft,
    IconArrowSharpTurnRight,
    IconArrowRoundaboutLeft,
    IconElevator,
  },

  props: {
    leg: {
      type: Object as PropType<Leg>,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    icons() {
      return [
        {
          value: "walk",
          icon: IconWalk,
        },
        {
          value: "bicycle",
          icon: IconBike,
        },
        {
          value: "car",
          icon: IconCar,
        },
        {
          value: "continue",
          icon: IconArrowUp,
        },
        {
          value: "slightly_left",
          icon: IconArrowBearLeft,
        },
        {
          value: "slightly_right",
          icon: IconArrowBearRight,
        },
        {
          value: "left",
          icon: IconArrowLeft,
        },
        {
          value: "right",
          icon: IconArrowRight,
        },
        {
          value: "hard_left",
          icon: IconArrowSharpTurnLeft,
        },
        {
          value: "hard_right",
          icon: IconArrowSharpTurnRight,
        },
        {
          value: "circle_counterclockwise",
          icon: IconArrowRoundaboutLeft,
        },
        {
          value: "circle_clockwise",
          icon: IconArrowRoundaboutLeft,
        },
        {
          value: "elevator",
          icon: IconElevator,
        },
      ];
    },
  },

  methods: {
    getIconComponent(iconValue: string) {
      const icon = this.icons.find(
        (item) => item.value.toLowerCase() === iconValue.toLowerCase(),
      );
      return icon ? icon.icon : IconWalk;
    },
    getDuration(leg: Leg) {
      const duration = this.$moment.duration(
        this.$moment(leg.endTime).diff(this.$moment(leg.startTime)),
      );

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());

      return hours >= 1 ? `${hours} h ${minutes} min` : `${minutes} min`;
    },
    getDistance(distance: number) {
      const meters = Math.floor(distance);
      const kilometers = Math.floor(meters / 1000);
      const remainingMeters = Math.floor(meters % 1000);

      return kilometers > 0
        ? `${kilometers} km ${remainingMeters} m`
        : `${meters} m`;
    },
    getStreetName(step: Step, index: number) {
      if (index === 0) {
        return this.leg.start.bikeStation?.name ?? step.streetName;
      }

      if (index === this.leg.steps.length - 1) {
        return this.leg.end.bikeStation?.name ?? step.streetName;
      }

      return step.streetName;
    },
    getRelativeDirection(direction: string) {
      switch (direction) {
        case "CONTINUE":
          return this.$t("route_planner.directions.continue");
        case "SLIGHTLY_LEFT":
          return this.$t("route_planner.directions.slightly_left");
        case "SLIGHTLY_RIGHT":
          return this.$t("route_planner.directions.slightly_right");
        case "LEFT":
          return this.$t("route_planner.directions.left");
        case "RIGHT":
          return this.$t("route_planner.directions.right");
        case "HARD_LEFT":
          return this.$t("route_planner.directions.hard_left");
        case "HARD_RIGHT":
          return this.$t("route_planner.directions.hard_right");
        case "CIRCLE_CLOCKWISE":
          return this.$t("route_planner.directions.circle_counterclockwise");
        case "CIRCLE_COUNTERCLOCKWISE":
          return this.$t("route_planner.directions.circle_counterclockwise");
        case "ELEVATOR":
          return this.$t("route_planner.directions.elevator");
        default:
          return "";
      }
    },
    getAbsoluteDirection(direction: string) {
      switch (direction) {
        case "NORTH":
          return this.$t("route_planner.directions.north");
        case "EAST":
          return this.$t("route_planner.directions.east");
        case "SOUTH":
          return this.$t("route_planner.directions.south");
        case "WEST":
          return this.$t("route_planner.directions.west");
        case "NORTHEAST":
          return this.$t("route_planner.directions.northeast");
        case "NORTHWEST":
          return this.$t("route_planner.directions.northwest");
        case "SOUTHEAST":
          return this.$t("route_planner.directions.southeast");
        case "SOUTHWEST":
          return this.$t("route_planner.directions.southwest");
        default:
          return "";
      }
    },
  },
});
