<template>
  <header class="hidden sm:block">
    <div class="flex justify-content-between mx-auto wp1200 py-4">
      <span class="text-2xl font-bold">
        {{ $t("bikes.heading") }}
      </span>
      <button
        event-name="bikesListGoButton"
        event-from="/bikes/map"
        v-if="$route.name === 'BikesMap'"
        @click="$router.push({ name: 'BikesList' })"
        class="flex align-items-center gap-2 border-none bg-white text-primary font-bold text-lg cursor-pointer"
      >
        <span>{{ $t("bikes.show_list") }}</span>
        <i class="icon-warpit_icon_TE_bullet_list font-bold"></i>
      </button>
      <button
        event-name="bikesMapGoButton"
        event-from="/bikes/list"
        v-if="$route.name === 'BikesList' || $route.name === 'BikesFavourite'"
        @click="$router.push({ name: 'BikesMap' })"
        class="flex align-items-center gap-2 border-none bg-white text-primary font-bold text-lg cursor-pointer"
      >
        <span>{{ $t("bikes.show_map") }}</span>
        <i class="icon-warpit_icon_map-import font-bold"></i>
      </button>
    </div>
  </header>

  <main>
    <router-view />
  </main>
</template>
