import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex align-items-center gap-2 w-full bg-red-50 border-round-xl px-3 py-2 text-red-500 font-semibold"
}
const _hoisted_2 = { class: "line-height-1" }
const _hoisted_3 = { class: "flex align-items-center gap-3 ml-auto" }
const _hoisted_4 = { class: "text-600 text-base font-normal line-height-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconAlertOctagon = _resolveComponent("IconAlertOctagon")!
  const _component_IconChevronRight = _resolveComponent("IconChevronRight")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.rainWarning)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_IconAlertOctagon),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.warningText), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("route_planner.forecast.source")) + ": ARSO", 1),
          _createVNode(_component_Button, {
            class: "button bg-white text-900 text-base p-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Weather' })))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconChevronRight)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}