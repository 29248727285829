
import { PropType, defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import { Filter } from "@/typings/News";
import { Event } from "@/typings/Event";
import EventsCard from "@/components/events/EventsCard.vue";
import Button from "primevue/button";
import PlaceholderImage from "@/components/PlaceholderImage.vue";
import { firebaseLogOutComeEvent } from "@/plugins/firebase";

export default defineComponent({
  props: {
    filters: {
      type: Object as PropType<Array<Filter>>,
      required: true,
    },
  },
  data: () => ({
    event: null as null | Event,
    latestEvents: null as null | Array<Event>,
    showDialog: false,
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    buildDate(datetime: string) {
      if (!datetime) return "";

      const currentDate = this.$moment().startOf("day");
      const date = this.$moment(datetime).startOf("day");

      const differenceInDays = date.diff(currentDate, "days");

      if (differenceInDays === -1) {
        return "";
      }

      if (differenceInDays <= 2 && differenceInDays > -1) {
        return this.$t(`events.date.day_${differenceInDays}`);
      }

      return this.$moment(datetime).fromNow();
    },
    async loadEvent() {
      return this.$http
        .get("api/v1/events/" + this.$route.params.id)
        .then((response) => {
          this.event = response.data;
        });
    },
    loadLatestEvents() {
      let params = {
        perPage: 5,
        filters: {
          category_id: this.event?.category_id ?? undefined,
        },
        orderBy: {
          datetime: "ASC",
        },
        datetime: [
          this.$moment().toDate(),
          this.$moment().add(1, "months").toDate(),
        ],
      };
      this.$http.get("api/v1/events", { params }).then((response) => {
        this.latestEvents = response.data.data;

        if (!this.latestEvents) {
          return;
        }

        this.latestEvents = this.latestEvents
          .filter((item: Event) => item.id !== this.event?.id)
          .slice(0, 3);
      });
    },
    goToEvents(filter: Filter) {
      this.$router.push({
        name: "Events",
        query: this.buildQuery(filter),
      });
    },
    buildQuery(filter: Filter) {
      const query: {
        [key: string]: string | number;
      } = {};

      if (typeof filter.id === "number") {
        query.category_id = filter.id;
      }

      if (typeof filter.id === "string") {
        query.view = filter.id;
      }

      return query;
    },
    saveEvent() {
      this.$http.post(`api/v1/events/${this.event?.id}/save`).then(() => {
        if (this.event === null) {
          return;
        }

        firebaseLogOutComeEvent("/events", "favoritesAdd");

        this.event.saved = true;
      });
    },
    removeSavedEvent() {
      this.$http.post(`api/v1/events/${this.event?.id}/unsave`).then(() => {
        if (this.event === null) {
          return;
        }

        this.showDialog = false;
        this.event.saved = false;
      });
    },
    subscribeToProvider(subscribed: boolean) {
      this.$http.post("/api/v1/events/provider-subscribers", {
        provider_id: this.event?.provider_id,
        subscribed,
      });
    },
  },
  mounted() {
    this.$emit("selectFilter", null);

    this.loadEvent().then(() => {
      this.loadLatestEvents();
    });
  },
  watch: {
    "$route.params.id": {
      handler() {
        if (this.$route.params.id) {
          this.loadEvent();
        }
      },
    },
  },
  components: {
    Dialog,
    Dropdown,
    EventsCard,
    Button,
    PlaceholderImage,
  },
});
