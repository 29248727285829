
import { defineComponent } from "vue";
import Button from "primevue/button";
import {
  firebaseLogClickEvent,
  firebaseLogOutComeEvent,
} from "@/plugins/firebase";

export default defineComponent({
  data: () => ({
    selectedItem: null as null | string,
    isValid: false,
    cardPaymentEnabled: false,
  }),
  mounted() {
    firebaseLogOutComeEvent(
      "/user/*/new-application",
      "eApplicationSubmissionProcess",
      {
        process_step: 2,
        e_application_type: "Vloga za izdajo lokacijske informacije",
      },
    );
  },
  methods: {
    selectItem(selectedItem: string) {
      this.selectedItem = selectedItem;
      this.isValid = true;
    },
    finishApplication() {
      if (this.selectedItem === "tax-confirmation") {
        firebaseLogClickEvent(
          "/user/*/new-application",
          "finishEApplicationButton",
        );
      }

      this.$emit("nextPage", this.selectedItem);
    },
  },
  components: { Button },
});
