import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import router from "@/plugins/router";
import store from "./store";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_GOOGLE_ANALYTICS_API_KEY,
  authDomain: process.env.VUE_APP_GOOGLE_ANALYTICS_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_GOOGLE_ANALYTICS_DATABASE_URL,
  projectId: process.env.VUE_APP_GOOGLE_ANALYTICS_PROJECT_ID,
  storageBucket: process.env.VUE_APP_GOOGLE_ANALYTICS_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_GOOGLE_ANALYTICS_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_GOOGLE_ANALYTICS_APP_ID,
  measurementId: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
};

function userInfo() {
  let userInfo = store.getters["user/analyticsInfo"];

  userInfo = Object.fromEntries(
    Object.entries(userInfo).map(([key, value]) => [
      key,
      value === undefined ? "null" : value,
    ]),
  );

  return userInfo;
}

const getPosition = (): Promise<{
  latitude: number | null;
  longitude: number | null;
}> => {
  return new Promise((resolve) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
        },
        (err) => {
          console.error("Error getting geolocation:", err);
          resolve({ latitude: null, longitude: null }); // Resolve with null values on error
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    } else {
      resolve({ latitude: null, longitude: null });
    }
  });
};

export let firebaseInstance: firebase.app.App | null = null;

function isMobileDevice() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

export function useFirebase(): firebase.app.App | null {
  if (process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLE !== "true") {
    return null;
  }

  if (firebaseInstance !== null) {
    return firebaseInstance;
  }

  firebaseInstance = firebase.initializeApp(firebaseConfig);

  router.beforeEach(async (to, from, next) => {
    if (firebaseInstance === null) {
      return;
    }

    let name = to.fullPath;
    const shortPath = to.path;

    let position = {
      latitude: null as null | number,
      longitude: null as null | number,
    };

    if (shortPath === "/news" || shortPath === "/events") {
      name = shortPath;
    }

    position = await getPosition();

    if (subCategories[name as string]) {
      const data = {
        source: "direct",
        medium: "organic",
        device: isMobileDevice() ? "mobile" : "desktop",
        app_name: "web",
        location_lat: position?.latitude ?? "null",
        location_lon: position?.longitude ?? "null",
        screen_name: name,
        category: subCategories[name as string].category.name,
        category_desc: subCategories[name as string].category.description,
        sub_category: subCategories[name as string].name,
        sub_category_desc: subCategories[name as string].description,
        ...userInfo(),
        landing_page: to.fullPath,
        page: from.fullPath,
      };

      firebaseInstance.analytics().logEvent("page_view", data);
    }
    next();
  });

  return firebaseInstance;
}

export async function firebaseLogEvent(
  eventName: string,
  page: string,
  path: string,
): Promise<void> {
  if (firebaseInstance === null) {
    return;
  }

  const position = await getPosition();

  const eventLogData = {
    page: page,
    location_lat: position?.latitude ?? "null",
    location_lon: position?.longitude ?? "null",
    category: subCategories[path].category.name,
    category_desc: subCategories[path].category.description,
    sub_name: subCategories[path].name,
    sub_category_desc: subCategories[path].description,
    ...userInfo(),
  };

  firebaseInstance.analytics().logEvent(eventName, eventLogData);
}

export async function firebaseLogClickEvent(
  page: string,
  eventName: string,
): Promise<void> {
  if (firebaseInstance === null) {
    return;
  }

  const position = await getPosition();

  let eventLogData = {};

  if (buttonEvents[eventName as string]) {
    eventLogData = {
      source: "direct",
      medium: "organic",
      device: isMobileDevice() ? "mobile" : "desktop",
      app_name: "Web",
      location: "null",
      page: page,
      target: page,
      location_lat: position?.latitude ?? "null",
      location_lon: position?.longitude ?? "null",
      category: buttonEvents[eventName].category.name,
      category_desc: buttonEvents[eventName].category.description,
      sub_category: buttonEvents[eventName].name,
      sub_category_desc: buttonEvents[eventName].description,
      ...userInfo(),
    };
  }

  if (Object.keys(eventLogData).length === 0) {
    return;
  }

  firebaseInstance.analytics().logEvent("click", eventLogData);
}

export async function firebaseLogOutComeEvent(
  page: string,
  eventName: string,
  extra?: any,
): Promise<void> {
  if (firebaseInstance === null) {
    return;
  }

  let eventLogData = {};
  let name = null;

  const position = await getPosition();

  if (outComeEvents[eventName as string]) {
    eventLogData = {
      source: "direct",
      medium: "organic",
      device: isMobileDevice() ? "mobile" : "desktop",
      app_name: "Web",
      location: "null",
      page: page,
      location_lat: position?.latitude ?? "null",
      location_lon: position?.longitude ?? "null",
      category: outComeEvents[eventName].category.name,
      category_desc: outComeEvents[eventName].category.description,
      sub_category: outComeEvents[eventName].name,
      sub_category_desc: outComeEvents[eventName].description,
      ...userInfo(),
      ...extra,
    };
    name = outComeEvents[eventName].key;
  }

  if (Object.keys(eventLogData).length === 0 || name === null) {
    return;
  }

  firebaseInstance.analytics().logEvent(name, eventLogData);
}

interface Category {
  name: string;
  description: string;
}

export const categories: Record<string, Category> = {
  home: { name: "home", description: "Domači zaslon" },
  register: { name: "register", description: "Registracija" },
  notifications: { name: "notifications", description: "Obvestila" },
  weather: { name: "weather", description: "Vremenska napoved" },
  publicWaste: { name: "public_waste", description: "Javni odpadki" },
  eForms: { name: "e_forms", description: "E-Vloge" },
  environment: { name: "environment", description: "Okolje" },
  favorites: { name: "favorites", description: "Priljubljeno" },
  auth: { name: "auth", description: "Avtentifikacija" },
  news: { name: "news", description: "Novice" },
  publicTransport: {
    name: "public_transport",
    description: "Javni prevoz",
  },
  privateWaste: { name: "private_waste", description: "Zasebni odpadki" },
  settings: { name: "settings", description: "Nastavitve" },
  consents: { name: "consents", description: "Soglasja" },
  loyalty: { name: "loyalty", description: "Zvestoba" },
  myVehicles: { name: "my_vehicles", description: "Moja vozila" },
  myLocations: { name: "my_locations", description: "Moje lokacije" },
  profile: { name: "user_profile_screen", description: "Profil" },
  technicalSupport: {
    name: "technical_support",
    description: "Tehnična podpora",
  },
  contactCity: {
    name: "contact_city",
    description: "Kontaktirajte mesto",
  },
  tellCity: { name: "tell_city", description: "Povej mestu" },
  parking: { name: "parking", description: "Parkirišča" },
  events: { name: "events", description: "Prireditve" },
  survey: { name: "survey", description: "Ankete" },
  linkedAccounts: {
    name: "linked_accounts",
    description: "Povezani računi",
  },
  payment: { name: "payment", description: "Plačila" },
  bikes: { name: "bikes", description: "Kolesa" },
  directus: { name: "directus", description: "Direktus" },
  sharedMobility: {
    name: "shared_mobility",
    description: "Deljena mobilnost",
  },
  traffic: { name: "traffic", description: "Prometne informacije" },
  aboutApplication: { name: "about_application", description: "O aplikaciji" },
  navigation: { name: "navigation", description: "Navigacija" },
};

export const subCategories: Record<
  string,
  { name: string | null; description: string | null; category: Category }
> = {
  // home
  "/": {
    name: "null",
    description: "null",
    category: categories.home,
  },
  "/home/feed": {
    name: "open_feed",
    description: "Uporabnik je odprl feed",
    category: categories.home,
  },
  "/home/my-city": {
    name: "open_feed",
    description: "Uporabnik je odprl feed",
    category: categories.home,
  },
  // auth
  "/login": {
    name: "open_login",
    description: "Uporabnik je odprl stran za prijavo",
    category: categories.auth,
  },
  "/forgot-password": {
    name: "open_forgot_password",
    description: "Uporabnik je odprl stran za pozabljeno geslo",
    category: categories.auth,
  },
  "/register": {
    name: "user_registration",
    description: "Uporabnik je začel registracijo",
    category: categories.auth,
  },
  // tell_city
  "/tell-city/feedback-dashboard": {
    name: "open_feedback_view_screen",
    description: "Zaslon za ogled povratnih informacij odprt",
    category: categories.tellCity,
  },
  "/tell-city/contact": {
    name: "open_contact_city_screen",
    description: "Zaslon za stik z mestom odprt",
    category: categories.tellCity,
  },
  "/tell-city/feedbacks": {
    name: "open_feedback_screen",
    description: "Zaslon povratnih informacij odprt",
    category: categories.tellCity,
  },
  "/tell-city/feedback-submit": {
    name: "open_feedback_form_screen",
    description: "Zaslon obrazca za povratne informacije odprt",
    category: categories.tellCity,
  },
  // my - super city
  "/user/my-points/overview": {
    name: "open_my_points_screen",
    description: "Zaslon mojih točk odprt",
    category: categories.loyalty,
  },
  "/user/my-points/transfer": {
    // new
    name: "open_my_points_transfer_screen",
    description: "Zaslon za prenos točk je odprt",
    category: categories.loyalty,
  },
  "/user/my-locations": {
    name: "open_my_locations_screen",
    description: "Zaslon mojih lokacij odprt",
    category: categories.myLocations,
  },
  "/user/my-waste": {
    name: "open_my_waste_removals_notification_screen",
    description: "Zaslon obvestil o odvozu odpadkov odprt",
    category: categories.privateWaste,
  },
  "/user/my-private-waste/timeline": {
    name: "open_my_waste_removals_notification_screen",
    description: "Zaslon obvestil o odvozu odpadkov odprt",
    category: categories.privateWaste,
  },
  "/user/my-vehicles": {
    name: "open_my_vehicles_screen",
    description: "Zaslon mojih vozil odprt",
    category: categories.myVehicles,
  },
  "/user/my-vehicles/add": {
    name: "open_parking_add_plate_screen",
    description: "Zaslon za dodajanje registrske tablice odprt",
    category: categories.myVehicles,
  },
  "/user/my-payments/open": {
    name: "open_my_payment_screen",
    description: "Zaslon mojih plačil odprt",
    category: categories.payment,
  },
  "/user/my-payments/history": {
    name: "open_my_payment_history_screen",
    description: "Zaslon zgodovine mojih plačil odprt",
    category: categories.payment,
  },
  "/user/my-payments/cards": {
    name: "open_my_payment_cards_screen",
    description: "Zaslon mojih plačilnih kartic odprt",
    category: categories.payment,
  },
  "/user/related-accounts": {
    name: "open_connect_new_supplier_screen",
    description: "Zaslon za povezavo z novim dobaviteljem odprt",
    category: categories.linkedAccounts,
  },
  "/user/related-account/add": {
    name: "",
    description: "",
    category: categories.linkedAccounts,
  },
  "/user/my-profile": {
    name: "open_user_profile_screen",
    description: "Zaslon uporabniškega profila odprt",
    category: categories.profile,
  },
  "/user/my-profile/edit": {
    name: "open_user_profile_edit_screen",
    description: "aslon za urejanje uporabniškega profila odprt",
    category: categories.profile,
  },
  "/user/my-profile/change-password": {
    name: "open_change_password",
    description: "Uporabnik je odprl stran za zamenjavo gesla",
    category: categories.profile,
  },
  "/user/my-profile/delete": {
    name: "open_confirm_delete_user_screen",
    description: "Zaslon za potrditev izbrisa uporabnika odprt",
    category: categories.profile,
  },
  "/user/my-consents": {
    name: "open_consents_screen",
    description: "Zaslon soglasij odprt",
    category: categories.consents,
  },

  // Mobility - parking
  "/parking/map": {
    name: "open_parking_map_screen",
    description: "Zaslon zemljevida parkirnih mest odprt",
    category: categories.parking,
  },
  "/parking/list": {
    name: "open_parking_list_screen",
    description: "Zaslon seznama parkirnih mest odprt",
    category: categories.parking,
  },
  "/parking/list/favourite": {
    name: "open_favourite_parking_list_screen",
    description: "Zaslon seznam priljubljenih parkirnih mest odprt",
    category: categories.parking,
  },

  // Mobility - bikes
  "/bikes/map": {
    name: "open_bikes_map_screen",
    description: "Zaslon zemljevida koles odprt",
    category: categories.bikes,
  },
  "/bikes/list": {
    name: "open_bikes_list_screen",
    description: "Zaslon seznama koles odprt",
    category: categories.bikes,
  },
  "/bikes/list/favourite": {
    name: "open_favourite_bikes_list_screen",
    description: "Zaslon seznam priljubljenih kolesnih postajališč odprt",
    category: categories.bikes,
  },

  // Mobility - transport
  "/public-transport/find-path": {
    name: "open_public_transport_screen",
    description: "Zaslon javnega prevoza odprt",
    category: categories.publicTransport,
  },
  "/public-transport/stations": {
    name: "open_public_transport_stations_screen",
    description: "Zaslon javnega prevoza postaj odprt",
    category: categories.publicTransport,
  },
  // Mobility - traffic
  "/traffic-info/list": {
    name: "open_traffic_info_list_screen",
    description: "Zaslon seznama prometnih informacij odprt",
    category: categories.traffic,
  },
  "/traffic-info/map": {
    name: "open_traffic_map_list_screen",
    description: "Zaslon zemljevida prometnih informacij odprt",
    category: categories.traffic,
  },

  // News and events
  "/news": {
    name: "open_news_screen",
    description: "Zaslon novic odprt",
    category: categories.news,
  },
  "/news?view=for-you&tab=1": {
    name: "open_news_subscriptions_screen",
    description: "Zaslon naročnin na novice odprt",
    category: categories.news,
  },
  "/events": {
    name: "open_events_screen",
    description: "Zaslon dogodkov odprt",
    category: categories.events,
  },
  "/events/subscriptions": {
    name: "open_event_subscriptions_screen",
    description: "Zaslon naročnin na dogodke odprt",
    category: categories.events,
  },

  // Environment
  "/weather": {
    name: "open_weather_forecast_screen",
    description: "Zaslon vremenske napovedi odprt",
    category: categories.weather,
  },
  "/weather/alerts": {
    // nevem
    name: "open_weather_notification_edit_screen",
    description: "Zaslon za urejanje vremenskih obvestil odprt",
    category: categories.weather,
  },
  "/air-quality": {
    name: "open_air_quality_history",
    description: "Uporabnik je odprl zaslon za prikaz kakovosti zraka",
    category: categories.environment,
  },
  /*   "/eco-island": { // Prašaj Primoža // public waste
    name: "",
    description: "",
    category: categories.environment,
  } */

  // Community
  "/survey/list/active": {
    name: "open_active_survey_screen",
    description: "Zaslon aktivnih anket odprt",
    category: categories.survey,
  },
  "/survey/list/history": {
    name: "open_history_survey_screen",
    description: "Zaslon zgodovine anket odprt",
    category: categories.survey,
  },

  // About app
  "/about-us": {
    name: "open_about_screen",
    description: "Zaslon o aplikaciji odprt",
    category: categories.aboutApplication,
  },
  "/terms-and-conditions": {
    name: "open_terms_and_conditions_screen",
    description: "Zaslon o spošni pogoji uporabe odprt",
    category: categories.aboutApplication,
  },
  "/privacy-policy": {
    name: "open_privacy_policy_screen",
    description: "Zaslon o politiki zasebnosti odprt",
    category: categories.aboutApplication,
  },
  "/declaration-conformity": {
    name: "open_declaration_conformity_screen",
    description: "Zaslon o izjavi o skladnosti odprt",
    category: categories.aboutApplication,
  },
  "/support": {
    name: "open_support_screen",
    description: "Zaslon za težave z aplikacijo odprt",
    category: categories.aboutApplication,
  },
  "/cookies": {
    name: "open_cookies_screen",
    description: "Zaslon za politika piškotkov odprt",
    category: categories.aboutApplication,
  },
};

const buttonEvents: Record<
  string,
  { name: string | null; description: string | null; category: Category }
> = {
  loginButtonClick: {
    name: "login_button_click",
    description: "Gumb za prijavo",
    category: categories.auth,
  },
  forgottenPasswordButton: {
    name: "forgotten_password_button_triggered",
    description: "Gumb za pozabljeno geslo",
    category: categories.auth,
  },
  forgottenPasswordSendCodeButton: {
    name: "forgotten_password_send_code_button_triggered",
    description: "Gumb za pošiljanje kode za pozabljeno geslo",
    category: categories.auth,
  },
  subscribeToNewsNotificationButton: {
    name: "subscribe_to_news_notification_button_triggered",
    description: "Gumb za naročanje na novice preko obvestil",
    category: categories.news,
  },
  subscribeToNewsProvidersButton: {
    name: "subscribe_to_news_providers_button_triggered",
    description: "Gumb za naročanje na ponudnike novic",
    category: categories.news,
  },
  noAccessToGuestButton: {
    // nevem kje
    name: "no_access_to_guest_button_triggered",
    description: "Gumb za nedostopnost za goste",
    category: categories.news,
  },
  registerContinueToVerificationButton: {
    name: "register_continue_to_verification_button_triggered",
    description: "Gumb za nadaljevanje registracije do preverjanja",
    category: categories.register,
  },
  bikesMapGoButton: {
    name: "bikes_map_go_button_triggered",
    description: "Gumb za zemljevid koles sprožen",
    category: categories.bikes,
  },
  bikesListGoButton: {
    // new
    name: "bikes_list_go_button_triggered",
    description: "Gumb za seznam koles sprožen",
    category: categories.bikes,
  },
  routeSearchButton: {
    name: "route_search_button_triggered",
    description: "Gumb za iskanje poti sprožen",
    category: categories.publicTransport,
  },
  saveEventSubscriptionButton: {
    name: "save_event_subscription_button_triggered",
    description: "Gumb za shranjevanje naročnine na dogodke sprožen",
    category: categories.notifications,
  },
  loginTitleButton: {
    name: "login_title_button_triggered",
    description: "Gumb za prijavo na uspešno registracijo sprožen",
    category: categories.register,
  },
  confirmRegistrationButton: {
    name: "confirm_registration_button_triggered",
    description: "Gumb za potrditev registracije sprožen",
    category: categories.register,
  },
  editProfileButton: {
    name: "edit_profile_button_triggered",
    description: "Gumb za urejanje profila sprožen",
    category: categories.profile,
  },
  saveChangesButtonClick: {
    name: "save_changes_button_click",
    description: "Gumb shrani spremembe",
    category: categories.consents,
  },
  sendFeedbackFormButton: {
    name: "send_feedback_form_button_click",
    description: "Gumb pošlji obrazec za povratne informacije",
    category: categories.tellCity,
  },
  submitNewApplicationButtonClick: {
    name: "submit_new_application_button_click",
    description: "Gumb oddaj novo prijavo",
    category: categories.eForms,
  },
  userProfileSaveChangesButtonClick: {
    name: "user_profile_save_changes_button_click",
    description: "Gumb shrani spremembe v uporabniškem profilu",
    category: categories.profile,
  },
  directusGoThereButtonClick: {
    name: "directus_go_there_button_click",
    description: "Gumb pojdi tja",
    category: categories.directus,
  },
  prefillUsersDataButton: {
    name: "prefill_users_data_button_triggered",
    description: "Gumb za samodejno izpolnjevanje uporabniških podatkov",
    category: categories.home,
  },
  nextSuppliersPaymentButton: {
    name: "next_button_triggered",
    description: "Gumb za nadaljevanje sprožen",
    category: categories.payment,
  },
  backHomeButton: {
    name: "back_home_button_triggered",
    description: "Gumb za vrnitev na začetni zaslon sprožen",
    category: categories.home,
  },
  connectPaymentProviderButton: {
    name: "connect_payment_provider_button_triggered",
    description: "Gumb za povezovanje s plačilnim ponudnikom sprožen",
    category: categories.payment,
  },
  buyTicketButton: {
    name: "buy_ticket_button_triggered",
    description: "Gumb za nakup vstopnice sprožen",
    category: categories.events,
  },
  nextStepEFormsButton: {
    name: "next_step_e_forms_button_triggered",
    description: "Gumb za naslednji korak v e-oblikah sprožen",
    category: categories.eForms,
  },
  nextPaymentStepButton: {
    name: "next_payment_step_button_triggered",
    description: "Gumb za naslednji korak pri plačilu sprožen",
    category: categories.payment,
  },
  finishPaymentButton: {
    name: "finish_payment_button_triggered",
    description: "Gumb za zaključek plačila sprožen",
    category: categories.payment,
  },
  finishEApplicationButton: {
    name: "finish_e_application_button_triggered",
    description: "Gumb za zaključek e-prijave sprožen",
    category: categories.eForms,
  },
  submitApplicationButton: {
    name: "submit_application_button_triggered",
    description: "Gumb za oddajo prijave sprožen",
    category: categories.eForms,
  },
  changePasswordButton: {
    name: "change_password_button_triggered",
    description: "Gumb za spremembo gesla sprožen",
    category: categories.profile,
  },
  profileExportButton: {
    name: "profile_export_button_triggered",
    description: "Gumb za izvoz podatkov profila sprožen",
    category: categories.profile,
  },
  userDeleteButton: {
    name: "user_delete_button_triggered",
    description: "Gumb za brisanje profila sprožen",
    category: categories.profile,
  },
  logoutButton: {
    name: "logout_button_triggered",
    description: "Gumb za odjavo sprožen",
    category: categories.profile,
  },
  paymentButtonClick: {
    name: "payment_button_click",
    description: "Gumb za plačilo",
    category: categories.payment,
  },
  wastePrivateWasteAcceptButtonClick: {
    name: "waste_private_waste_accept_button_click",
    description: "Gumb sprejmi kanale naročnin na obvestila",
    category: categories.privateWaste,
  },
  wasteFormLeaveEditingButtonClick: {
    name: "waste_form_leave_editing_button_click",
    description: "Gumb zapusti urejanje obrazca",
    category: categories.privateWaste,
  },
  formSubmissionButtonClick: {
    name: "form_submission_button_click",
    description: "Gumb za oddajo obrazca",
    category: categories.eForms,
  },
};

const outComeEvents: Record<
  string,
  {
    key: string;
    name: string | null;
    description: string | null;
    category: Category;
  }
> = {
  registration: {
    key: "registration",
    name: "registration_process_finish",
    description: "Uporabnik je zaključil registracijo",
    category: categories.register,
  },
  registrationProcessFirstStep: {
    key: "registration_process",
    name: "registration_process_step_1",
    description: "Uporabnik na koraku 1 za registracijo.",
    category: categories.register,
  },
  registrationProcessSecondStep: {
    key: "registration_process",
    name: "registration_process_step_2",
    description: "Uporabnik na koraku 2 za registracijo.",
    category: categories.register,
  },
  appOpen: {
    key: "app_open",
    name: "user_opened_the_app",
    description: "Uporabnik odprl aplikacijo.",
    category: categories.home,
  },
  // Notificatiosn process
  notificationRegistrationProcessWaste: {
    key: "notification_registration_process",
    name: "notification_registration_process_waste",
    description: "Uporabnik vpisuje formo.",
    category: categories.privateWaste,
  },
  environmentAlertsNotificationRegistrationProcess: {
    key: "notification_registration_process",
    name: "weather_alerts_notification_registration_process",
    description: "Uporabnik vpisuje formo.",
    category: categories.environment,
  },
  notificationRegistrationProcessEvents: {
    key: "notification_registration_process",
    name: "notification_registration_process_events",
    description: "Uporabnik vpisuje formo.",
    category: categories.events,
  },
  notificationRegistrationProcessNews: {
    key: "notification_registration_process",
    name: "notification_registration_process_news",
    description: "Uporabnik vpisuje formo.",
    category: categories.news,
  },
  // Notifications
  notificationRegistrationWaste: {
    key: "notification_registration",
    name: "notification_registration_waste",
    description: "Uporabnik se je uspešno prijavil na obveščanje.",
    category: categories.privateWaste,
  },
  notificationRegistrationWeather: {
    key: "notification_registration",
    name: "notification_registration_weather",
    description: "Uporabnik se je uspešno prijavil na obveščanje.",
    category: categories.environment,
  },
  notificationRegistratioEvents: {
    key: "notification_registration",
    name: "notification_registration_events",
    description: "Uporabnik se je uspešno prijavil na obveščanje.",
    category: categories.events,
  },
  notificationRegistrationNews: {
    key: "notification_registration",
    name: "notification_registration_news",
    description: "Uporabnik se je uspešno prijavil na obveščanje..",
    category: categories.news,
  },
  wasteRegistrationProcess: {
    key: "waste_registration_process",
    name: "private_waste_notification_registration_process",
    description: "Uporabnik vpisuje formo.",
    category: categories.privateWaste,
  },
  wasteRegistration: {
    key: "waste_registration",
    name: "private_waste_registration",
    description: "Uporabnik je uspešno naročil odvoz kosovnih odpadkov.",
    category: categories.privateWaste,
  },
  wasteNotifications: {
    key: "waste_notifications",
    name: "private_waste_notification_subscription",
    description: "Uporabnik se je uspešno prijavil na obveščanje.",
    category: categories.privateWaste,
  },
  environmentAlertsNotificationSub: {
    key: "weather_notification",
    name: "weather_alerts_notification_subscription",
    description: "Uporabnik se je uspešno prijavil na obveščanje.",
    category: categories.environment,
  },
  newsNotificationRegistrationProcess: {
    key: "newsletter_registration_process",
    name: "news_notification_registration_process",
    description: "Uporabnik vpisuje formo.",
    category: categories.news,
  },
  newsNotificationRegistration: {
    key: "newsletter_registration",
    name: "news_alerts_notification_subscription",
    description: "Uporabnik se je uspešno prijavil na obveščanje.",
    category: categories.news,
  },
  eventsNotificationRegistrationProcess: {
    key: "events_registration_process",
    name: "events_notification_registration_process",
    description: "Uporabnik vpisuje formo.",
    category: categories.events,
  },
  eventsNotificationRegistration: {
    key: "events_registration",
    name: "events_notification_registration",
    description: "Uporabnik se je uspešno prijavil na obveščanje.",
    category: categories.events,
  },
  paymentProcess: {
    key: "payment_process",
    name: "payment_process",
    description: "Uporabnik je v postopku plačila računa",
    category: categories.payment,
  },
  payment: {
    key: "payment",
    name: "payment_successful",
    description: "Uporabnik je uspešno plačal račun",
    category: categories.payment,
  },
  eApplicationSubmissionProcess: {
    key: "e_application_submission_process",
    name: "e_application_submission_process",
    description: "Uporabnik je v postopku oddaje e-vloge.",
    category: categories.eForms,
  },
  eApplicationSubmission: {
    key: "e_application_submission",
    name: "e_application_submission",
    description: "Uporabnik uspešno oddal e-vloge.",
    category: categories.eForms,
  },
  profileVerification: {
    key: "profile_verification",
    name: "profile_verification",
    description: "Uporabnik uspešno verificiral svoj profil.",
    category: categories.profile,
  },
  favoritesAdd: {
    key: "add_to_favorite",
    name: "add_to_favorite",
    description: "Uporabnik je dodal predmet med priljubljene",
    category: categories.favorites,
  },
  surveySubmisionProcess: {
    key: "survey_submision_process",  
    name: "survey_submision_process",
    description: "Uporabnik je začel anketo",
    category: categories.survey,
  }
};
