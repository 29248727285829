import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex align-items-center justify-content-center bg-primary-50 border-circle border-white border-2 w-3rem h-3rem text-primary -ml-4"
}
const _hoisted_2 = {
  key: 0,
  class: "flex align-items-center justify-content-center bg-primary-50 border-circle border-white border-2 w-3rem h-3rem text-primary -ml-4"
}
const _hoisted_3 = { class: "flex flex-column gap-2" }
const _hoisted_4 = { class: "text-xl font-semibold" }
const _hoisted_5 = { class: "text-sm m-0" }
const _hoisted_6 = { class: "flex flex-column gap-2 mt-2" }
const _hoisted_7 = { class: "flex flex-column w-full" }
const _hoisted_8 = { class: "flex justify-content-between text-base" }
const _hoisted_9 = { class: "flex flex-column gap-1" }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = {
  key: 0,
  class: "text-sm text-600"
}
const _hoisted_12 = { class: "flex justify-content-end mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "plainButton flex align-items-center gap-2 flex-initial cursor-pointer w-full sm:w-fit pl-4",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: option.value
        }, [
          (
          option.enabled && _ctx.options.filter((o) => o.enabled).indexOf(option) < 4
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), { class: "text-base" }))
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      (_ctx.options.filter((o) => o.enabled).length > 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " +" + _toDisplayString(_ctx.options.filter((o) => o.enabled).length - 4), 1))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent('IconChevronDown')))
    ]),
    _createVNode(_component_OverlayPanel, {
      ref: "overlayPanel",
      class: "route-planner-panel",
      onHide: _ctx.saveSelection
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("route_planner.preferred_mode")), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("route_planner.preferred_mode_description")), 1),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                key: option.value,
                class: "flex gap-3 align-items-start"
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), { class: "text-xl" })),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t(`route_planner.modes.${option.value}`)), 1),
                      (option.description)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(option.description), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_InputSwitch, {
                      modelValue: option.enabled,
                      "onUpdate:modelValue": ($event: any) => ((option.enabled) = $event),
                      disabled: _ctx.isLastEnabled(option),
                      onChange: ($event: any) => (_ctx.handleToggle(option)),
                      class: "flex-shrink-0"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])
                  ]),
                  _createVNode(_component_Divider)
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Button, {
              class: "button primary w-full sm:w-fit",
              label: _ctx.$t('global.ok'),
              onClick: _ctx.toggle
            }, null, 8, ["label", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onHide"])
  ], 64))
}